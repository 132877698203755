import React, { useEffect, useState } from 'react'
import { MdPrint } from 'react-icons/md'
import { Link, NavLink, useParams } from 'react-router-dom'
import { Button, Input, Message } from 'semantic-ui-react'
import {
  getScheduledEvent,
  getScheduledEventResultsWithSuggestedRanking,
  setEventRankedStatus,
  updateRankingsForEvent
} from '../api/meetApi'
import { messaging } from '../utils/toast'
import Ably from '../api/ablyApi'

const EventRankPage = () => {
  const [event, setEvent] = useState(null)
  const [eventResults, setEventResults] = useState([])
  const [rankedEvents, setRankedEvents] = useState([])

  const params = useParams()

  const channel = Ably.channels.get('update-events')

  useEffect(() => {
    const eventId = params.scheduledEventId

    let isMounted = true
    if (eventId && isMounted) {
      getScheduledEvent(eventId).then((data) => {
        if (data.status === 'error') {
          messaging.errorMessage(data.message)
          return
        } else {
          setEvent(data)
        }
      })

      getScheduledEventResultsWithSuggestedRanking(eventId).then((data) => {
        setEventResults(data)
        setRankedEvents(data)
      })
    }

    return () => {
      isMounted = false
    }
  }, [params.scheduledEventId])

  const updateRankForParticipant = (participantResultId, rank) => {
    eventResults.forEach((eventResult) => {
      if (
        eventResult.scheduledEventChildParticipantId === participantResultId
      ) {
        eventResult.rank = rank
      }
    })
    setRankedEvents([...eventResults])
  }

  return (
    <div>
      {event && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4 className='my-1'>{`
        ${event.id} - ${event.name} - ${event.minimumAge} - ${event.maximumAge} for ${event.gender}
      `}</h4>
          <NavLink
            className='p-3'
            to={'/meets/eventPrintResults/' + event.id}
            target='_blank'
          >
            <MdPrint size='30px' />
          </NavLink>
          <Button
            content='Entry'
            as={Link}
            to={'/meets/eventDataEntry/' + event.id}
          />
          <Button
            content='Results'
            as={Link}
            to={'/meets/eventResults/' + event.id}
          />
        </div>
      )}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          content='Save Ranking'
          color='blue'
          onClick={() => {
            updateRankingsForEvent(event.id, eventResults).then((data) => {
              if (data.status === 'error') {
                messaging.errorMessage(data.message)
                return
              }
              messaging.successMessage(data.message)
              setEventRankedStatus(event.id, '1')
              channel.publish('update-events', { eventId: event.id })
            })
          }}
        />
        <Message className='ml-4' color='pink' size='mini'>
          <Message.Header>Warning</Message.Header>
          <p>Saving rank info overwrites previous ranking.</p>
        </Message>
      </div>

      <div className='results-data'>
        {event && rankedEvents && rankedEvents.length > 0 && (
          <div className='event-results my-1'>
            <table>
              <thead>
                <tr>
                  <th>AN</th>
                  <th>Name</th>
                  <th>
                    {event.eventType === 'Field' ||
                    event.name.indexOf('stacle') > 0
                      ? 'Atmpt'
                      : ''}
                  </th>
                  <th className='pl-3'>
                    {event.eventType === 'Track' ? 'Time' : 'Feet/Inches'}
                  </th>
                  <th>Rank</th>
                </tr>
              </thead>
              <tbody>
                {rankedEvents.map((row) => {
                  const useValue =
                    event.eventType === 'Track'
                      ? row.minutesFeet + ':' + row.secondsInches
                      : row.minutesFeet + "' " + row.secondsInches + '"'
                  const useClass =
                    row.ageMismatch === '1' ? 'pr-1 age-mismatch' : 'pr-1'
                  const useTitle = row.ageMismatch === '1' ? 'Age mismatch' : ''
                  return (
                    <tr key={row.scheduledEventChildParticipantId}>
                      <td className='pr-1'>{row.assignedNumber}</td>
                      <td title={useTitle} className={useClass}>
                        {row.name}
                      </td>
                      <td className='text-center'>
                        {event.eventType === 'Field' ||
                        event.name.indexOf('stacle') > 0
                          ? row.attempt
                          : ''}
                      </td>
                      <td className='pl-4 pr-3'>{useValue}</td>
                      <td className='text-center'>
                        <Input
                          style={{ maxWidth: '40px' }}
                          size='small'
                          onFocus={(e) => {
                            e.target.select()
                          }}
                          value={row.rank}
                          onChange={(e, { value }) => {
                            let useValue = row.rank
                            if (['', '1', '2', '3', '4'].includes(value)) {
                              useValue = value
                            }
                            updateRankForParticipant(
                              row.scheduledEventChildParticipantId,
                              useValue
                            )
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default EventRankPage
