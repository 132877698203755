import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Table } from '../components/form/tables/SortableTable'
import { getRegisteredParents } from '../api/meetApi'

const ParentsSummaryPage = () => {
  const [parents, setParents] = useState([])

  const updateParents = useCallback(
    (parentsId) => {
      const fndParents = parents.find((prnts) => {
        return prnts.id === parentsId
      })
      fndParents.paid = '1'
      const replParents = parents.filter((prnts2) => {
        return prnts2.id !== parentsId
      })
      setParents([...replParents, fndParents])
    },
    [parents]
  )

  useEffect(() => {
    getRegisteredParents()
      .then((data) => {
        setParents(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const ParentsLink = ({ row }) => {
    // console.log(row)
    return (
      <NavLink title='Go to parents record' to={'/parents/' + row.values.id}>
        {row.values.id}
      </NavLink>
    )
  }

  const PaidCheckbox = useCallback(
    ({ row }) => {
      return (
        <input
          type='checkbox'
          id={`paid-parents-${row.values.paid}`}
          defaultChecked={row.values.paid === '1'}
          onClick={(e) => {
            e.preventDefault()
            const confirmed = window.confirm(
              'Mark parents paid with id: ' + row.values.id
            )
            if (confirmed) {
              updateParents(row.values.id)
              return true
            }
            return false
          }}
          disabled={row.values.paid === '1'}
        />
      )
    },
    [updateParents]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Parents',
        columns: [
          {
            Header: 'Id',
            accessor: 'id',
            Cell: ParentsLink
          },
          {
            Header: 'Parents',
            accessor: 'parentsNames'
          },
          {
            Header: 'Paid',
            accessor: 'paid',
            Cell: PaidCheckbox
          }
        ]
      }
    ],
    [PaidCheckbox]
  )

  return (
    <div>
      <h1>Registered Parents</h1>
      <div
        style={{
          overflow: 'auto',
          height: 'calc(100vh - 125px)'
        }}
      >
        <Styles>
          <Table columns={columns} data={parents} />
        </Styles>
      </div>
    </div>
  )
}

export default ParentsSummaryPage

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      padding-right: 1rem;
      text-align: left;
    }
    tr:first-child th:first-child {
      display: none;
    }
  }
`
