import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import {
  FaFireExtinguisher,
  FaUserFriends,
  FaLockOpen,
  FaLock,
  FaKey,
  FaUserCog,
  FaRunning,
  FaRegAddressCard
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { messaging } from '../utils/toast'
import { logOutUser } from '../store/userSlice'
import { setSideBarOpen } from '../store/appSlice'

const SideMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const user = useSelector((state) => state.user)
  const app = useSelector((state) => state.app)
  const dispatch = useDispatch()
  const [useClass, setUseClass] = useState('sidemenu-wrapper')
  const logoutHandler = (e) => {
    e.preventDefault()
    messaging.successMessage('You have successfully logged out!')
    dispatch(logOutUser())
    navigate('/')
  }
  const bodyListener = useCallback(
    (e) => {
      if (
        app.sideBarOpen &&
        !e.target.classList.contains('sidemenu-wrapper') &&
        !e.target.classList.contains('stay-open')
      ) {
        dispatch(setSideBarOpen(false))
      }
    },
    [dispatch, app.sideBarOpen]
  )

  useEffect(() => {
    document.body.addEventListener('click', bodyListener)
    const clName =
      app.sideBarOpen === true ? 'sidemenu-wrapper open' : 'sidemenu-wrapper'
    setUseClass(clName)

    return () => {
      document.body.removeEventListener('click', bodyListener)
    }
  }, [bodyListener, app.sideBarOpen])

  const getNavLinkClass = (path) => {
    const cls =
      location.pathname.substring(0, path.length) === path ? 'active' : ''
    return cls
  }

  const meetsRef = useRef()

  const handleMeetsClick = (e) => {
    e.preventDefault()
    if (meetsRef.current.classList.contains('open')) {
      meetsRef.current.classList.remove('open')
    } else {
      meetsRef.current.classList.add('open')
    }
  }

  return (
    <div className={useClass}>
      <ul>
        {user && user.loggedIn ? (
          <>
            <li>
              <NavLink to='/contacts'>
                <FaRegAddressCard />
                Contacts
              </NavLink>
            </li>
            <li>
              <NavLink to='/parents'>
                <FaUserFriends />
                Parents
              </NavLink>
            </li>
            <li ref={meetsRef} className='has-subnav'>
              <button
                onClick={handleMeetsClick}
                className={'stay-open ' + getNavLinkClass('/meets')}
              >
                <FaRunning />
                Meets
              </button>
              <div className='subnav'>
                <ul>
                  <li>
                    <NavLink to='/meets/dashboard'>Dashboard</NavLink>
                  </li>
                  <li>
                    <NavLink to='/meets/participants'>Participants</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to='/registeredParents'>
                      Registered Parents
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to='/meets/eventsListing'>Events</NavLink>
                  </li>
                  <li>
                    <NavLink to='/meets/eventDataEntry'>
                      Event Data Entry
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/meets/eventsStatus'>Events Status</NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <NavLink to='/pendingEmails'>
                <FaFireExtinguisher />
                Pending Emails
              </NavLink>
            </li>
            <li>
              <NavLink to='/profile'>
                <FaUserCog />
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? 'off' : 'on')}
                to='#'
                onClick={logoutHandler}
              >
                <FaLockOpen />
                Logout
              </NavLink>
            </li>
          </>
        ) : (
          <>
            <li>
              <NavLink to='/login'>
                <FaLock />
                Login
              </NavLink>
            </li>
            <li>
              <NavLink to='/register'>
                <FaKey />
                Register
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}

export default SideMenu
