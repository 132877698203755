import firebase, { auth, storage } from '../config/firebase'
import { setUserProfileData } from './firestoreService'
import { messaging } from '../utils/toast'

export const fbdb = firebase.database()

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map((e) =>
      Object.assign({}, e[1], { id: e[0] })
    )
  }
}

export function signInWithEmail(creds) {
  return auth.signInWithEmailAndPassword(creds.email, creds.password)
}

export function signOutFirebase() {
  return auth.signOut()
}

export async function registerInFirebase(creds) {
  try {
    const result = await auth.createUserWithEmailAndPassword(
      creds.email,
      creds.password
    )
    await result.user.updateProfile({ displayName: creds.displayName })
    return await setUserProfileData(result.user)
  } catch (error) {
    throw error
  }
}

export async function socialLogin(selectedProvider) {
  return new Promise(async (resolve, reject) => {
    let provider
    if (selectedProvider === 'google') {
      provider = new firebase.auth.GoogleAuthProvider()
    }

    try {
      const result = await auth.signInWithPopup(provider)
      if (result.additionalUserInfo.isNewUser) {
        await setUserProfileData(result.user)
      }
      resolve(true)
    } catch (error) {
      messaging.errorMessage(error.message)
      reject(false)
    }
  })
}

export function updateUserPassword(creds) {
  const user = auth.currentUser
  return user.updatePassword(creds.newPassword1)
}

export function uploadToFirebaseStorage(file, filename) {
  const user = auth.currentUser
  const storageRef = storage.ref()
  return storageRef.child(`${user.uid}/user_images/${filename}`).put(file)
}

export function deleteFromFirebaseStorage(filename) {
  const userUid = auth.currentUser.uid
  const storageRef = storage.ref()
  const photoRef = storageRef.child(`${userUid}/user_images/${filename}`)
  return photoRef.delete()
}

export function addEventChatComment(eventId, values) {
  const user = auth.currentUser
  const newComment = {
    displayName: user.displayName,
    photoURL: user.photoURL,
    uid: user.uid,
    text: values.comment,
    date: Date.now(),
    parentId: values.parentId
  }
  return fbdb.ref(`chat/${eventId}`).push(newComment)
}

export function getEventChatRef(eventId) {
  return fbdb.ref(`chat/${eventId}`).orderByKey()
}

export function getUserFeedRef() {
  const user = auth.currentUser
  if (!user) {
    return
  }
  return fbdb.ref(`posts/${user.uid}`).orderByKey().limitToLast(5)
}

export const checkGroupPasskey = (passphrase) => {
  return new Promise(async (resolve, reject) => {
    const docRef = fbdb.ref('groups/group/mgmt/' + passphrase)
    if (docRef) {
      await docRef.once('value', (snap) => {
        if (snap.exists()) {
          resolve(true)
        } else {
          reject(false)
        }
      })
    }
  })
}
