import api from './axiosConfig'

const rootMeetAdminUrl = '/Meet_admin'
const rootContactAdminUrl = '/Contacts'
const sendReadyEmailsUrl = '/EmailService/sendReadyEmails'

export const getPendingEmails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetAdminUrl + '/getReadyEmails')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const sendReadyEmails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(sendReadyEmailsUrl)

      if (res.data && res.data.success) {
        resolve(res.data.success.message)
      } else {
        reject('Error sending emails')
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getContacts = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootContactAdminUrl)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getContactWebCredentials = (contactId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootContactAdminUrl + '/getWebsiteCredentialsForContact',
        { contactId }
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getAllParents = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetAdminUrl + '/get_all_parents')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getParents = (parentsId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetAdminUrl + '/get_parents/' + parentsId)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getParentsChildren = (parentsId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/get_parents_children/' + parentsId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const unregisterFamily = (parentsId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/unregister_family/' + parentsId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const updateContactWebsitePassword = (contactId, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootContactAdminUrl + '/resetPasswordForContact',
        {
          contactId,
          password
        }
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}
