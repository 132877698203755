import { configureStore } from '@reduxjs/toolkit'
import { appSlice } from './appSlice'
import { modalSlice } from './modalSlice'
import { userSlice } from './userSlice'

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    app: appSlice.reducer,
    modals: modalSlice.reducer
  }
})

export default store
