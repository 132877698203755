import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import MyTextInput from '../components/form/MyTextInput'
import * as Yup from 'yup'
import { messaging } from '../utils/toast'
import { getUserProfile, updateProfile } from '../api/userApi'
import Loading from '../components/Loading'
import ChangePassword from '../components/ChangePassword'

export default function ProfileForm() {
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    getUserProfile()
      .then((data) => {
        setProfile(data)
      })
      .catch((err) => {
        messaging.errorMessage(err)
      })
  }, [])

  if (!profile) {
    return <Loading />
  }

  return (
    <div>
      <div className='mb-2'>
        <Formik
          initialValues={{
            firstName: profile.firstName,
            lastName: profile.lastName
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required')
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              updateProfile(values)
                .then((data) => {
                  messaging.successMessage(data.statusMessage)
                  resetForm({
                    values: {
                      firstName: data.data.firstName,
                      lastName: data.data.lastName
                    }
                  })
                })
                .catch((err) => {
                  messaging.errorMessage(err)
                })
            } catch (error) {
              messaging.errorMessage(error.message)
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <>
              <h4>Update Profile</h4>
              <Form className='ui form'>
                <MyTextInput name='firstName' placeholder='First Name' />
                <MyTextInput name='lastName' placeholder='Last Name' />
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid || !dirty}
                  type='submit'
                  size='large'
                  positive
                  content='Update profile'
                />
              </Form>
            </>
          )}
        </Formik>
      </div>
      <ChangePassword />
    </div>
  )
}
