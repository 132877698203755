import { createSlice } from '@reduxjs/toolkit'

const defaultUser = {
  loggedIn: false,
  user_id: null,
  firstName: '',
  lastName: '',
  username: ''
}

const getInitialState = () => {
  const userInfo = localStorage.getItem('userInfo')
  if (userInfo) {
    const userState = JSON.parse(userInfo)
    return { ...userState, loggedIn: true }
  }
  return defaultUser
}

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    setUser: (state, { payload }) => {
      return (state = { ...payload, loggedIn: true })
    },
    logOutUser: (state) => {
      localStorage.removeItem('userInfo')
      localStorage.removeItem('accessJWT')
      return (state = defaultUser)
    }
  }
})

export const { setUser, logOutUser } = userSlice.actions

export const selectUserState = (state) => state.user

export default userSlice.reducer
