import React, { useEffect, useState } from 'react'
import Loading from './Loading'

export const ParticipantDivisionLeaders = ({
  participantsWithSummary,
  ageGroup,
  gender
}) => {
  const [participantLeaders, setParticipantLeaders] = useState(null)
  useEffect(() => {
    // const ageGroups = ['3-4', '5-6', '7-8', '9-10', '11-12', '13-14', '15+']
    let mounted = true
    let summaries = []

    const useSummaries = participantsWithSummary || []
    if (mounted) {
      const partsByAgeGender = useSummaries.filter((part) => {
        return (
          part.age_group === ageGroup &&
          part.gender === gender &&
          part.total_points > 0
        )
      })
      partsByAgeGender.sort((a, b) => {
        return b.total_points - a.total_points
      })

      if (partsByAgeGender.length > 0) {
        const max = partsByAgeGender[0]
        const allPrts = partsByAgeGender.filter((mprt) => {
          return mprt.total_points === max.total_points
        })
        summaries = [...summaries, ...allPrts]
      }
    }

    setParticipantLeaders(summaries)
    return () => {
      mounted = false
    }
  }, [participantsWithSummary, ageGroup, gender])

  if (!participantLeaders) {
    return <Loading />
  }

  const partsBase = participantLeaders.map((prt) => {
    if (prt) {
      return (
        <div key={prt.age_group + prt.gender}>
          <strong>{prt.age_group}:</strong> {prt.first_name} {prt.last_name} -{' '}
          {prt.total_points}
        </div>
      )
    }
    return null
  })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '.5rem'
      }}
    >
      {partsBase}
    </div>
  )
}
