import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { getPendingEmails } from '../api/adminApi'
import { sendReadyEmails } from '../api/adminApi'
import Loading from '../components/Loading'
import { messaging } from '../utils/toast'

const EmailMonitor = () => {
  const [pendingEmails, setPendingEmails] = useState([])

  useEffect(() => {
    getPendingEmails().then((data) => {
      setPendingEmails(data.data)
    })
  }, [])

  return (
    <div>
      {!pendingEmails && <Loading />}
      {pendingEmails && pendingEmails.length === 0 && (
        <h3>No Emails Pending</h3>
      )}
      {pendingEmails && pendingEmails.length > 0 && (
        <>
          <h1>Emails pending</h1>
          <ul>
            {pendingEmails.map((email) => {
              return (
                <li key={email.id}>
                  {email.sendToEmail + ' : ' + email.sendToFormal}
                </li>
              )
            })}
          </ul>
          <Button
            onClick={() => {
              sendReadyEmails()
                .then((res) => {
                  messaging.successMessage('Emails Sent!')
                  setPendingEmails([])
                })
                .catch((err) => {
                  console.error(err)
                  messaging.errorMessage('There was an error sending emails')
                })
            }}
          >
            Send Pending Emails
          </Button>
        </>
      )}
    </div>
  )
}

export default EmailMonitor
