import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoading: true,
    sideBarOpen: false,
    currentMeetName: ''
  },
  reducers: {
    setCurrentMeetName: (state, action) => {
      state.currentMeetName = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSideBarOpen: (state, action) => {
      state.sideBarOpen = action.payload
    }
  }
})

export const {
  setIsLoading,
  setSideBarOpen,
  setCurrentMeetName
} = appSlice.actions

export default appSlice.reducer
