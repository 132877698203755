import React from 'react'
import styled from 'styled-components'
import { RiFileWarningLine } from 'react-icons/ri'

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <RiFileWarningLine size='200' color='#900' />
      <h3>Page Not Found</h3>
    </NotFoundWrapper>
  )
}

export default NotFound

const NotFoundWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
`
