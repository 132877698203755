import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Search, Label } from 'semantic-ui-react'
import { getScheduledEvents } from '../api/meetApi'
import Loading from './Loading'

const initialState = {
  loading: false,
  results: [],
  value: ''
}

function dataReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query }
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results }
    case 'UPDATE_SELECTION':
      return { ...state, value: '' }

    default:
      throw new Error()
  }
}

const resultRenderer = ({ title }) => <Label content={title} />

function SearchScheduledEvents({ setEventId, onFocus }) {
  const [source, setSource] = useState([])

  useEffect(() => {
    let isMounted = true
    getScheduledEvents().then((data) => {
      if (isMounted) {
        setSource(
          data.map((res) => {
            return {
              title:
                res.id +
                ' - ' +
                res.name +
                ' ages ' +
                res.minimumAge +
                ' - ' +
                res.maximumAge +
                ' for ' +
                res.gender,
              id: res.id,
              raceid: res.raceId,
              eventtype: res.eventType,
              gender: res.gender,
              minimumage: res.minimumAge,
              maximumage: res.maximumAge,
              name: res.name,
              overallposition: res.overallPosition
            }
          })
        )
        const searchComp = document.getElementById('scheduled-events-search')
        if (searchComp) {
          searchComp.focus()
        }
      }
    })

    return () => {
      isMounted = false
    }
  }, [])

  const [state, dispatch] = React.useReducer(dataReducer, initialState)
  const { loading, results, value } = state

  const timeoutRef = React.useRef()
  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current)
      dispatch({ type: 'START_SEARCH', query: data.value })

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch({ type: 'CLEAN_QUERY' })
          return
        }

        const re = new RegExp(_.escapeRegExp(data.value), 'i')
        const isMatch = (result) => {
          return re.test(result.title)
        }

        dispatch({
          type: 'FINISH_SEARCH',
          results: _.filter(source, isMatch)
        })
      }, 300)
    },
    [source]
  )
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  if (!source) {
    return <Loading />
  }

  return (
    <Search
      selectFirstResult={true}
      id='scheduled-events-search'
      onFocus={onFocus}
      loading={loading}
      onResultSelect={(e, data) => {
        const { id } = data.result
        dispatch({
          type: 'UPDATE_SELECTION',
          selection: data.result.title
        })
        setEventId(id)
      }}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={value}
    />
  )
}

export default SearchScheduledEvents
