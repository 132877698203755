import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Search, Label, Button } from 'semantic-ui-react'
import { getAllParents } from '../api/adminApi'
import { setParentsPaid } from '../api/meetApi'
import { messaging } from '../utils/toast'
import Loading from './Loading'

const initialState = {
  loading: false,
  results: [],
  value: ''
}

function dataReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query }
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results }
    case 'UPDATE_SELECTION':
      return { ...state, value: '' }

    default:
      throw new Error()
  }
}

const resultRenderer = ({ title }) => <Label content={title} />

function SearchParents({ setParentsId }) {
  const [source, setSource] = useState([])

  const doSetParentsPaid = (parentsId) => {
    const parents = source.find((p) => {
      return p.id === parentsId
    })
    if (!parents) {
      messaging.errorMessage('Parents not found')
      return
    }
    const confirmed = window.confirm(
      'Set parents ' + parents.title + ' as paid?'
    )
    if (confirmed) {
      setParentsPaid(parentsId).then(() => {
        const newSource = source.map((p) => {
          if (p.id === parentsId) {
            p.paid = 'Yes'
          }
          return p
        })
        setSource(newSource)
        messaging.successMessage(
          'Parents marked as paid and child participants created'
        )
      })
    }
  }

  useEffect(() => {
    let isMounted = true
    getAllParents().then((data) => {
      if (isMounted)
        setSource(
          data.map((res) => {
            return {
              title: res.parentsNames,
              registered: res.registeredBool ? 'Yes' : 'No',
              paid: res.paidBool ? 'Yes' : 'No',
              id: res.id
            }
          })
        )
    })

    return () => {
      isMounted = false
    }
  }, [])

  const [state, dispatch] = React.useReducer(dataReducer, initialState)
  const { loading, results, value } = state

  const timeoutRef = React.useRef()
  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current)
      dispatch({ type: 'START_SEARCH', query: data.value })

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch({ type: 'CLEAN_QUERY' })
          return
        }

        const re = new RegExp(_.escapeRegExp(data.value), 'i')
        const isMatch = (result) => {
          return re.test(result.title)
        }

        dispatch({
          type: 'FINISH_SEARCH',
          results: _.filter(source, isMatch)
        })
      }, 300)
    },
    [source]
  )
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  if (!source || source.length === 0) {
    return <Loading />
  }

  return (
    <>
      <Search
        selectFirstResult={true}
        loading={loading}
        onResultSelect={(e, data) => {
          dispatch({
            type: 'UPDATE_SELECTION',
            selection: data.result.title
          })
          setParentsId(data.result.id)
        }}
        onSearchChange={handleSearchChange}
        resultRenderer={resultRenderer}
        results={results}
        value={value}
      />
      <div
        style={{
          height: 'calc(100vh - 200px)',
          marginTop: '1rem',
          overflowY: 'auto'
        }}
      >
        <table style={{ marginTop: '1rem' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Paid?</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {source
              .filter((p) => {
                return p.registered === 'Yes'
              })
              .map((parent) => {
                return (
                  <tr key={parent.id}>
                    <td>
                      <NavLink to={'/parents/' + parent.id}>
                        {parent.title}
                      </NavLink>
                    </td>
                    <td>{parent.paid}</td>
                    <td colSpan='2'>&nbsp;</td>
                    <td>
                      <Button
                        disabled={parent.paid === 'Yes'}
                        onClick={() => {
                          doSetParentsPaid(parent.id)
                        }}
                        size='tiny'
                        color='blue'
                        content='Mark Paid'
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SearchParents
