import jwtDecode from 'jwt-decode'

export const tokenCurrent = () => {
  const token = localStorage.getItem('accessJWT')
  if (token) {
    const tokenExpiration = jwtDecode(token).exp
    const dateNow = new Date()
    const dateNowTime = dateNow.getTime() / 1000
    return tokenExpiration > dateNowTime
  }
  return false
}
