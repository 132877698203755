import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Button, Container, Icon } from 'semantic-ui-react'
import { getParents, unregisterFamily } from '../api/adminApi'
import { getParentsChildren } from '../api/adminApi'
import SearchParents from '../components/SearchParents'
import { messaging } from '../utils/toast'
import { setParentsPaid } from '../api/meetApi'
import { Credential } from '../components/Credential'
import ModalWrapper from '../components/modals/ModalWrapper'

const ParentsPage = () => {
  const [parents, setParents] = useState(null)
  const [children, setChildren] = useState([])
  const [showCreds, setShowCreds] = useState(false)

  const navigate = useNavigate()
  const params = useParams()

  const hideCreds = () => setShowCreds(false)

  const setParentsId = (parentsId) => {
    navigate('/parents/' + parentsId)
  }

  const doSetParentsPaid = () => {
    const confirmed = window.confirm(
      'Set parents ' + parents.parentsNames + ' as paid?'
    )
    if (confirmed) {
      setParentsPaid(parents.id).then(() => {
        parents.paid = '1'
        setParents({ ...parents })
        messaging.successMessage(
          'Parents marked as paid and child participants created'
        )
      })
    }
  }

  const loadParents = useCallback((parentsId) => {
    getParentsChildren(parentsId).then((data) => {
      if (data.status === 'success') {
        setChildren(data.data)
      }
    })
    getParents(parentsId).then((data) => {
      if (data.status === 'success') {
        setParents(data.data)
      }
    })
  }, [])

  useEffect(() => {
    if (params.id) {
      loadParents(params.id)
    }
  }, [params.id, loadParents])

  const doUnregisterFamily = () => {
    const doIt = window.confirm(
      'Are you sure you would like to unregister this family?'
    )
    if (doIt) {
      unregisterFamily(parents.id).then((data) => {
        messaging.successMessage(data.message)
        setParents({ ...parents, registered: 'Not registered' })
      })
    }
  }

  return (
    <Container>
      <h1>Parents</h1>
      <div style={{ display: parents ? 'none' : 'block' }}>
        <SearchParents setParentsId={setParentsId} />
      </div>
      <Container
        style={{
          height: 'calc(100vh - 200px)',
          marginTop: '1rem',
          overflowY: 'auto'
        }}
      >
        {parents && (
          <div>
            <Button
              icon
              onClick={() => {
                setParents(null)
                setChildren([])
                navigate('/parents')
              }}
            >
              Back to Parents
              <Icon className='pl-1 pr-1' name='search' />
            </Button>
            <Button onClick={() => setShowCreds(true)}>Reset Password</Button>
          </div>
        )}
        <Container>
          {parents && (
            <div>
              <h1 className='mt-2'>{parents.parentsNames}</h1>
              <div className='mt-2'>
                <strong>Registration status:</strong> {parents.registered}
                <br />
                <strong>Paid status:</strong>{' '}
                {parents.paid === '1' ? 'Yes' : 'No'}
              </div>
              {parents.registered === 'Registered' && (
                <>
                  <div className='mt-2'>
                    <Button
                      color='red'
                      content='Unregister Family'
                      onClick={doUnregisterFamily}
                    />
                    <Button
                      color='blue'
                      disabled={parents.paid === '1'}
                      content={'Mark Parents Paid'}
                      onClick={doSetParentsPaid}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </Container>
        {children.length > 0 && (
          <Container className='mt-3'>
            <h3 style={{ borderBottom: '1px solid #ddd' }}>Children</h3>
            <table>
              <thead>
                <tr>
                  <th className='text-left'>Name</th>
                  <th className='text-left'>Gender</th>
                  <th className='text-left'>Birthday</th>
                  <th className='text-left'>Registered?</th>
                </tr>
              </thead>
              <tbody>
                {children.map((child) => {
                  return (
                    <tr key={child.id}>
                      <td className='pr-2'>
                        <NavLink to={'/child/' + child.id}>
                          {child.name}
                        </NavLink>
                      </td>
                      <td className='pr-2'>{child.gender}</td>
                      <td className='pr-2'>{child.birthDayUsShortDate}</td>
                      <td className='pr-2'>
                        {child.isRegistered ? 'Yes' : 'No'}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {parents && (
              <>
                <Button
                  as={NavLink}
                  to={'/child?parentsId=' + parents.id}
                  className='mt-1'
                  content='Add Child'
                />
                {showCreds && (
                  <ModalWrapper
                    header='Reset Password'
                    cancelCallback={hideCreds}
                    size={'tiny'}
                  >
                    <Credential
                      onSavePasswordSuccess={hideCreds}
                      contactId={parents.contactId}
                    />
                  </ModalWrapper>
                )}
              </>
            )}
          </Container>
        )}
      </Container>
    </Container>
  )
}

export default ParentsPage
