import React from 'react'
import { useSelector } from 'react-redux'
import LoginForm from '../../views/LoginForm'
import RegisterForm from '../../views/RegisterForm'
import UnauthModal from '../../views/UnauthModal'
import ExtendSessionModal from '../modals/ExtendSessionModal'

export default function ModalManager() {
  const modalLookup = {
    LoginForm,
    RegisterForm,
    UnauthModal,
    ExtendSessionModal
  }
  const currentModal = useSelector((state) => state.modals)

  let renderedModal
  if (currentModal) {
    const { modalType, modalProps } = currentModal
    const ModalComponent = modalLookup[modalType]
    renderedModal = <ModalComponent {...modalProps} />
  }

  return <span>{renderedModal}</span>
}
