import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { socialLogin } from '..//firestore/firebaseService'

export default function SocialLogin() {
  const navigate = useNavigate()
  function handleSocialLogin(provider) {
    socialLogin(provider)
      .then(() => {
        navigate('/')
      })
      .catch(() => {
        return false
      })
  }
  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault()
          handleSocialLogin('google')
        }}
        icon='google'
        fluid
        color='google plus'
        content='Login with Google'
      />
    </>
  )
}
