import api from './axiosConfig'

const rootAuthUrl = '/Authentication'
const registrationUrl = rootAuthUrl + '/register'
const loginUrl = rootAuthUrl + '/login'
const logoutUrl = rootAuthUrl + '/logout'
const jwtRefreshUrl = rootAuthUrl + '/refresh_token'
const rootMembersUrl = '/Members'
const profileUrl = rootMembersUrl + '/profile'
const updatePasswordUrl = rootMembersUrl + '/updatePassword'

export const userRegistration = (frmData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(registrationUrl, frmData)

      if (res.data.status === 'success') {
        resolve(res.data)
      }
      if (res.data.status === 'error') {
        reject(res.data.error.message)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const updateProfile = (frmData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(profileUrl, frmData)

      if (res.data.status === 'success') {
        resolve(res.data)
      }
      if (res.data.status === 'error') {
        reject(res.data.error.message)
      }
    } catch (error) {
      reject({ status: 'error', message: error.error })
    }
  })
}

export const login = (frmData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(loginUrl, frmData)

      if (res.data.status === 'success') {
        saveLocalToken(res.data.userInfo.token)
        localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
        resolve(res.data)
      }
      if (res.data.status === 'error') {
        clearSessionData()
        reject(res.data.error.message)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const refreshToken = () => {
  try {
    api
      .get(jwtRefreshUrl)
      .then((resp) => {
        if (resp.data.status === 'success') {
          saveLocalToken(resp.data.newToken)
        }
      })
      .catch((error) => {
        console.log('Error refreshing token')
      })
  } catch (error) {
    console.log('Error refreshing token')
  }
}

export const saveLocalToken = (token) => {
  localStorage.setItem('accessJWT', token)
}

export const getUserProfile = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(profileUrl)
      resolve(res.data)
    } catch (error) {
      clearSessionData()
      resolve(error.message)
    }
  })
}

export const logout = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      await api.get(logoutUrl, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessJWT')
        }
      })
      clearSessionData()
      resolve(true)
    } catch (error) {
      reject(error)
    }
  })
}

export const updatePassword = (frmData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(updatePasswordUrl, frmData)

      if (res.data.status === 'success') {
        resolve(res.data)
      }
      if (res.data.status === 'error') {
        reject(res.data.error.message)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const clearSessionData = () => {
  localStorage.removeItem('accessJWT')
  localStorage.removeItem('userInfo')
}
