import React from 'react'
import ReactDom from 'react-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import MyTextInput from '../components/form/MyTextInput'
import { Button, Label, Header, Icon } from 'semantic-ui-react'
import { login } from '../api/userApi'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser } from '../store/userSlice'
import { closeModal } from '../store/modalSlice'

function Home() {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  return ReactDom.createPortal(
    <>
      <div className='overlay'></div>
      <div className='home-wrapper'>
        <div className='home-inner'>
          <div className='home-login-form'>
            <Header as='h2' icon textAlign='center'>
              <Icon name='lock' size='big' circular />
              <Header.Content>Homeschool Trackmeet Admin</Header.Content>
            </Header>
            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={Yup.object({
                username: Yup.string().required(),
                password: Yup.string().required()
              })}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  const userData = await login(values)
                  dispatch(setUser({ ...userData.userInfo, loggedIn: true }))
                  setSubmitting(false)
                  dispatch(closeModal())
                  navigate('/meets/dashboard')
                } catch (error) {
                  console.log(error)
                  setErrors({ auth: 'Invalid username or password' })
                  setSubmitting(false)
                }
              }}
            >
              {({ isSubmitting, isValid, dirty, errors }) => (
                <Form className='ui form'>
                  <MyTextInput name='username' placeholder='Username' />
                  <MyTextInput
                    name='password'
                    placeholder='Password'
                    type='password'
                  />
                  {errors.auth && (
                    <Label
                      color='red'
                      style={{ marginBottom: 10 }}
                      content={errors.auth}
                    />
                  )}
                  <Button
                    loading={isSubmitting}
                    disabled={!dirty || !isValid || isSubmitting}
                    type='submit'
                    fluid
                    size='large'
                    color='teal'
                    content='Login'
                  />
                </Form>
              )}
            </Formik>
          </div>
          <p>
            This is the homeschool trackmeet administrative tool. This is meant
            for administering track meet data by those who are authorized only.
          </p>
          <p>
            If you are looking for the official homeschool trackmeet website,
            please go{' '}
            <a href='https://homeschooltrackmeet.inviteto.com'>here</a>
          </p>
        </div>
      </div>
    </>,
    document.body
  )
}

export default Home
