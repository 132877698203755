import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logOutUser } from './store/userSlice'
import NSF from './views/NSF'
import { refreshToken } from './api/userApi'
import { tokenCurrent } from './utils/userUtils'

const PrivateRoute = ({
  component: Component,
  props,
  role = 'Meet Administrator',
  ...rest
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [isNSF, setIsNSF] = useState(true)
  const timeoutRef = useRef(0)

  useEffect(() => {
    if (!tokenCurrent()) {
      setIsAuthenticated(false)
      dispatch(logOutUser())
      navigate('/')
    } else {
      if (
        user.roles &&
        (user.roles.includes('WebAdmin') || user.roles.includes(role))
      ) {
        setIsNSF(false)
      }
      setIsAuthenticated(true)
    }

    refreshToken()

    timeoutRef.current = setInterval(() => {
      if (!tokenCurrent()) {
        dispatch(logOutUser())
        setIsAuthenticated(false)
      }
    }, 60000)

    return () => {
      clearInterval(timeoutRef.current)
    }
  }, [user, dispatch, navigate, role])

  if (isAuthenticated === null) {
    return <></>
  }

  if (isNSF && isAuthenticated) {
    return <NSF />
  }

  if (isAuthenticated) {
    return <Component {...props} {...rest} />
  }
}

export default PrivateRoute
