import { Field, Form, Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Label } from 'semantic-ui-react'
import { createOrUpdateChild, getChild } from '../api/meetApi'
import MyDateInput from '../components/form/MyDateInput'
import MyTextInput from '../components/form/MyTextInput'
import * as Yup from 'yup'
import { messaging } from '../utils/toast'
import Loading from '../components/Loading'
import { useNavigate, useParams } from 'react-router-dom'

const ChildPage = () => {
  const [child, setChild] = useState(null)
  const navigate = useNavigate()
  const [parentsId, setParentsId] = useState(null)

  const params = useParams()

  const loadChild = useCallback(
    (id) => {
      getChild(params.id).then((data) => {
        if (data.status === 'success') {
          setChild(data.data)
        } else {
          messaging.errorMessage(data.message)
        }
      })
    },
    [params.id]
  )

  const handleCheckboxChange = (e, values) => {
    child[values.name] = values.checked ? '1' : '0'
    child[values.name + 'Bool'] = values.checked
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setParentsId(urlParams.get('parentsId') || null)
    if (params.id) {
      loadChild(params.id)
    } else {
      const blankChild = {
        id: null,
        firstName: '',
        lastName: '',
        birthDay: '',
        gender: '',
        preKFieldEvents: '0',
        preKFieldEventsBool: false,
        obstacleCourse: '0',
        obstacleCourseBool: false,
        softballThrow: '0',
        softballThrowBool: false,
        shotPut: '0',
        shotPutBool: false,
        longJump: '0',
        longJumpBool: false,
        highJump: '0',
        highJumpBool: false,
        run25Yard: '0',
        run25YardBool: false,
        run50Yard: '0',
        run50YardBool: false,
        run100Yard: '0',
        run100YardBool: false,
        run200Yard: '0',
        run200YardBool: false,
        run400Yard: '0',
        run400YardBool: false,
        run800Yard: '0',
        run800YardBool: false,
        run1600Yard: '0',
        run1600YardBool: false
      }
      setChild(blankChild)
    }
  }, [params.id, loadChild])

  if (child == null) {
    return <Loading />
  }

  return (
    <div
      style={{
        height: 'calc(100vh - 65px)',
        overflowY: 'auto'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4 className='mr-2'>Child Registration Information</h4>
        {child && (
          <Button
            content='Back to Parents'
            onClick={() => {
              const useParentsId = child.parentsId || parentsId
              navigate('/parents/' + useParentsId)
            }}
          />
        )}
      </div>
      <Formik
        initialValues={{
          id: child.id,
          gender: child.gender,
          firstName: child.firstName,
          lastName: child.lastName,
          birthDay: child.birthDayUsShortDate,
          preKFieldEvents: child.preKFieldEventsBool,
          obstacleCourse: child.obstacleCourseBool,
          softballThrow: child.softballThrowBool,
          shotPut: child.shotPutBool,
          longJump: child.longJumpBool,
          highJump: child.highJumpBool,
          run25Yard: child.run25YardBool,
          run50Yard: child.run50YardBool,
          run100Yard: child.run100YardBool,
          run200Yard: child.run200YardBool,
          run400Yard: child.run400YardBool,
          run800Yard: child.run800YardBool,
          run1600Yard: child.run1600YardBool
        }}
        validationSchema={Yup.object({
          birthDay: Yup.string().required(),
          firstName: Yup.string().required(),
          lastName: Yup.string().required(),
          gender: Yup.string().required(),
          preKFieldEvents: Yup.boolean(),
          obstacleCourse: Yup.boolean(),
          softballThrow: Yup.boolean(),
          shotPut: Yup.boolean(),
          longJump: Yup.boolean(),
          highJump: Yup.boolean(),
          run25Yard: Yup.boolean(),
          run50Yard: Yup.boolean(),
          run100Yard: Yup.boolean(),
          run200Yard: Yup.boolean(),
          run400Yard: Yup.boolean(),
          run800Yard: Yup.boolean(),
          run1600Yard: Yup.boolean()
        })}
        onSubmit={async (values, { setSubmitting }) => {
          const d = new Date(values.birthDay)
          const updateValues = {
            firstName: values.firstName,
            lastName: values.lastName,
            birthDay: d.toLocaleDateString('en-US'),
            gender: values.gender
          }
          if (parentsId) {
            updateValues.parentsId = parentsId
          }
          createOrUpdateChild({ ...child, ...updateValues })
            .then((data) => {
              if (data.status === 'success') {
                messaging.successMessage(data.statusMessage)
              } else {
                messaging.errorMessage(data.statusMessage)
              }
              setSubmitting(false)
            })
            .catch((error) => {
              setSubmitting(false)
            })
        }}
      >
        {({ isSubmitting, isValid, errors }) => {
          return (
            <Form className='ui form'>
              <MyTextInput name='firstName' placeholder='First Name' />
              <MyTextInput name='lastName' placeholder='Last Name' />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MyDateInput name='birthDay' placeholder='Birthday' />{' '}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    width: '175px'
                  }}
                >
                  <div>
                    <Field
                      type='radio'
                      className='pl-1'
                      name='gender'
                      value='m'
                    />{' '}
                    Male
                  </div>
                  <div>
                    <Field
                      type='radio'
                      className='pl-1'
                      name='gender'
                      value='f'
                    />{' '}
                    Female
                  </div>
                </div>
              </div>
              {errors.auth && (
                <Label
                  color='red'
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <h4 className='mt-2 registered-events-checkboxes'>
                Registered Events
              </h4>
              <div
                className='m-2'
                style={{
                  height: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <Checkbox
                  label='Obstacle Course'
                  name='obstacleCourse'
                  defaultChecked={child.obstacleCourseBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='Softball Throw'
                  name='softballThrow'
                  defaultChecked={child.softballThrowBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='Shot Put'
                  name='shotPut'
                  defaultChecked={child.shotPutBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='High Jump'
                  name='highJump'
                  defaultChecked={child.highJumpBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='Long Jump'
                  name='longJump'
                  defaultChecked={child.longJumpBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='PreK Field Events'
                  name='preKFieldEvents'
                  defaultChecked={child.preKFieldEventsBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='25 Yard Run'
                  name='run25Yard'
                  defaultChecked={child.run25YardBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='50 Yard Run'
                  name='run50Yard'
                  defaultChecked={child.run50YardBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='100 Yard Run'
                  name='run100Yard'
                  defaultChecked={child.run100YardBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='200 Yard Run'
                  name='run200Yard'
                  defaultChecked={child.run200YardBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='400 Yard Run'
                  name='run400Yard'
                  defaultChecked={child.run400YardBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='800 Yard Run'
                  name='run800Yard'
                  defaultChecked={child.run800YardBool}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  label='1600 Yard Run'
                  name='run1600Yard'
                  defaultChecked={child.run1600YardBool}
                  onChange={handleCheckboxChange}
                />
              </div>
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
                type='submit'
                fluid
                size='large'
                color='teal'
                content='Save'
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ChildPage
