import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Divider } from 'semantic-ui-react'
import {
  getChildParticipant,
  getChildParticipantActivities
} from '../api/meetApi'
import { messaging } from '../utils/toast'

const ParticipantPage = () => {
  const [participant, setParticipant] = useState(null)
  const [trackEvents, setTrackEvents] = useState([])
  const [fieldEvents, setFieldEvents] = useState([])
  const [totalTrackPoints, setTotalTrackPoints] = useState(0)
  const [totalFieldPoints, setTotalFieldPoints] = useState(0)

  const params = useParams()

  useEffect(() => {
    const id = params.id

    let isMounted = true
    if (id && isMounted) {
      getChildParticipant(id).then((data) => {
        if (data.status === 'error') {
          messaging.errorMessage(data.message)
          return
        } else {
          setParticipant(data)
        }
      })
      getChildParticipantActivities(id).then((data) => {
        setFieldEvents(data.fieldEvents)
        setTrackEvents(data.trackEvents)
        setTotalFieldPoints(data.totalFieldPoints)
        setTotalTrackPoints(data.totalTrackPoints)
      })
    }

    return () => {
      isMounted = false
    }
  }, [params.id])

  return (
    <div>
      {participant && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ddd',
              paddingBottom: '1em'
            }}
          >
            <h4>
              {participant.name}
              <br />
              Age: {participant.age}
              &nbsp;&nbsp;-&nbsp;&nbsp;Assigned #:&nbsp;{participant.an}
            </h4>
            <Button className='ml-4' as={Link} to='/meets/participants'>
              Back to Participants
            </Button>
          </div>
          <div style={{ height: 'calc(100vh - 150px)', overflow: 'auto' }}>
            {participant.registeredEvents.length > 0 && (
              <>
                <div className='mt-2'>
                  <h5>Registered Events:</h5>
                  <ul>
                    {participant.registeredEvents.map((evt) => {
                      return <li key={evt}>{evt}</li>
                    })}
                  </ul>
                </div>
                <Divider />
              </>
            )}
            {((trackEvents && trackEvents.length > 0) ||
              (fieldEvents && fieldEvents > 0)) && (
              <h3 className='my-2'>Event Results</h3>
            )}
            {trackEvents && trackEvents.length > 0 && (
              <>
                <h3>Track Events</h3>
                <table>
                  <thead>
                    <tr>
                      <th className='text-left pr-2'>Event</th>
                      <th className='text-left pr-2'>Minutes</th>
                      <th className='text-left pr-2'>Seconds</th>
                      <th className='text-left pr-2'>Scratch</th>
                      <th className='text-left pr-2'>Position</th>
                      <th className='text-left pr-2'>Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trackEvents.map((event) => {
                      return (
                        <tr key={event.eventName}>
                          <td className='pr-2'>{event.eventName}</td>
                          <td>{event.minutes}</td>
                          <td>{event.seconds}</td>
                          <td>{event.scratch ? 'Yes' : 'No'}</td>
                          <td>{event.overallPosition}</td>
                          <td>{event.points}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <p className='m-1'>
                  <strong>Total track points:</strong>&nbsp;&nbsp;
                  {totalTrackPoints}
                </p>
              </>
            )}
            {fieldEvents && fieldEvents.length > 0 && (
              <>
                <h3>Field Events</h3>
                <table>
                  <thead>
                    <tr>
                      <th className='text-left pr-2'>Event</th>
                      <th className='text-left pr-2'>Attempt</th>
                      <th className='text-left pr-2'>Feet</th>
                      <th className='text-left pr-2'>Inches</th>
                      <th className='text-left pr-2'>Scratch</th>
                      <th className='text-left pr-2'>Position</th>
                      <th className='text-left pr-2'>Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fieldEvents.map((event) => {
                      return (
                        <tr key={event.eventName}>
                          <td className='pr-2'>{event.eventName}</td>
                          <td>{event.raceId}</td>
                          <td>{event.feet}</td>
                          <td>{event.inches}</td>
                          <td>{event.scratch ? 'Yes' : 'No'}</td>
                          <td>{event.overallPosition}</td>
                          <td>{event.points}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <p className='m-1'>
                  <strong>Total field points:</strong>&nbsp;&nbsp;
                  {totalFieldPoints}
                </p>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ParticipantPage
