import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal } from 'semantic-ui-react'
import { closeModal } from '../../store/modalSlice'

export default function ModalWrapper({
  children,
  size,
  header,
  cancelCallback
}) {
  const dispatch = useDispatch()
  return (
    <Modal
      open={true}
      onClose={() => dispatch(closeModal())}
      size={size}
      dimmer='blurring'
    >
      {header && (
        <Modal.Header>
          {' '}
          {header}{' '}
          <Button
            onClick={() => {
              dispatch(closeModal())
              if (cancelCallback !== null) {
                cancelCallback()
              }
            }}
            content='Cancel'
            style={{ position: 'relative', top: '-5px', float: 'right' }}
          />
        </Modal.Header>
      )}
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  )
}
