import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Divider, Modal } from 'semantic-ui-react'
import { openModal } from '../store/modalSlice'

export default function UnauthModal({ setModalOpen }) {
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch()

  function handleOpenLoginModal(modalType) {
    dispatch(openModal({ modalType }))
    setOpen(false)
  }

  const navigate = useNavigate()

  const handleClose = () => {
    navigate('/')
  }

  return (
    <Modal open={open} size='mini' onClose={handleClose}>
      <Modal.Header content='You need to be signed in to do that' />
      <Modal.Content>
        <p>Please either login or register to see this content</p>
        <Button.Group widths={4}>
          <Button
            fluid
            color='teal'
            content='Login'
            onClick={() => handleOpenLoginModal('LoginForm')}
          />
          <Button.Or />
          <Button
            color='green'
            content='Register'
            onClick={() => handleOpenLoginModal('RegisterForm')}
          />
        </Button.Group>
        <Divider />
        <div className='text-center'>
          <p>Or click cancel to continue as a guest</p>
        </div>
        <Button onClick={handleClose} content='Cancel' />
      </Modal.Content>
    </Modal>
  )
}
