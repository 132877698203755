import React, { useEffect, useRef, useState } from 'react'
import { Input } from 'semantic-ui-react'
import {
  getChildParticipantByAn,
  getScheduledEvent,
  getScheduledEventResults
} from '../api/meetApi'
import { messaging } from '../utils/toast'
import SearchScheduledEvents from '../components/SearchScheduledEvents'
import EventDataEntryForm from '../components/form/EventDataEntryForm'
import { deleteScheduledEventResult } from '../api/meetApi'
import { MdDeleteForever } from 'react-icons/md'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

const EventDataEntryPage = () => {
  const [event, setEvent] = useState(null)
  const [eventId, setEventId] = useState(null)
  const [childParticipant, setChildParticipant] = useState()
  const [eventResults, setEventResults] = useState([])
  const childAnRef = useRef()

  const navigate = useNavigate()
  const params = useParams()

  const setEventIdAndFocusAN = (eventId) => {
    getScheduledEvent(eventId).then((data) => {
      setEvent(data)
    })
    setEventResults([])
    if (childAnRef && childAnRef.current) {
      childAnRef.current.focus()
    }
  }

  useEffect(() => {
    if (params.scheduledEventId) {
      setEventIdAndFocusAN(params.scheduledEventId)
    } else {
      setEvent(null)
      setEventResults([])
    }
  }, [params.scheduledEventId])

  useEffect(() => {
    if (eventId) {
      navigate('/meets/eventDataEntry/' + eventId)
    }
  }, [eventId, navigate])

  const findParticipantByAn = (an) => {
    getChildParticipantByAn(an).then((data) => {
      if (data.status && data.status === 'error') {
        soundAlert()
        messaging.errorMessage(data.message)
      } else {
        const useGender = data.gender === 'Female' ? 'Girls' : 'Boys'
        if (useGender !== event.gender) {
          soundAlert()
          messaging.errorMessage('This event is for ' + event.gender)
          setChildParticipant(null)
        } else {
          setChildParticipant(data)
        }
        childAnRef.current.inputRef.current.value = ''
      }
    })
  }

  const soundAlert = () => {
    const player = document.getElementById('audio-element')
    player.play()
  }

  const getEventResultsData = (eventId) => {
    getScheduledEventResults(eventId).then((data) => {
      setEventResults(data.data)
    })
  }

  useEffect(() => {
    if (event) {
      getEventResultsData(event.id)
    }
  }, [event])

  const handleEventEntrySuccess = () => {
    messaging.successMessage('Record added')
    setChildParticipant(null)
    if (childAnRef && childAnRef.current) {
      childAnRef.current.focus()
    }
    getEventResultsData(event.id)
  }

  const handleEventEntryCancel = () => {
    messaging.errorMessage('Entry cancelled', {
      autoClose: 1000
    })
    setChildParticipant(null)
    childAnRef.current.focus()
  }

  const handleAnChange = (e) => {
    const strVal = '' + e.target.value
    if (strVal.length === 3) {
      findParticipantByAn(strVal)
      e.target.value = ''
    }
  }

  return (
    <div>
      {' '}
      <audio id='audio-element'>
        <source src='/sounds/computer-error-alert.mp3'></source>
      </audio>
      <div
        style={{
          zIndex: '1000',
          backgroundColor: 'white',
          position: 'fixed',
          top: '60px',
          margin: '0',
          width: '350px',
          paddingTop: '5px'
        }}
      >
        <h3 className='mb-1'>Event Data Entry</h3>
        <SearchScheduledEvents
          className='pb-1'
          onFocus={() => {
            setChildParticipant(null)
          }}
          setEventId={setEventId}
        />
      </div>
      {event && (
        <div
          className='card'
          style={{
            width: 'calc(100% - 30px)',
            maxWidth: '500px',
            zIndex: '900',
            backgroundColor: 'white',
            position: 'fixed',
            top: '145px',
            margin: '0',
            paddingTop: '12px'
          }}
        >
          <h4 className='my-1'>
            {event.id} - {event.name} ages - {event.minimumAge}-
            {event.maximumAge} for {event.gender}
          </h4>
          <Input
            type='number'
            ref={childAnRef}
            onChange={handleAnChange}
            placeholder='Enter Assigned #'
            name='assignedNumber'
          />
          {childParticipant && (
            <div>
              <h5
                className='pt-1'
                style={{
                  color: '#3f0000',
                  borderBottom: '1px solid #3f0000',
                  width: '300px'
                }}
              >
                {childParticipant.an} - {childParticipant.name} -{' '}
                {childParticipant.age} - {childParticipant.gender}
              </h5>
              <EventDataEntryForm
                soundAlert={soundAlert}
                childParticipant={childParticipant}
                event={event}
                onSuccess={handleEventEntrySuccess}
                onCancel={handleEventEntryCancel}
              />
            </div>
          )}
        </div>
      )}
      <div
        style={{
          height: 'calc(100vh - 280px)',
          zIndex: '90',
          position: 'relative',
          top: '190px',
          overflow: 'auto',
          width: '100%'
        }}
      >
        {eventResults && eventResults.length > 0 && (
          <div className='event-results my-1'>
            <div style={{ display: 'flex', paddingBottom: '4px' }}>
              <NavLink
                style={{
                  paddingRight: '10px',
                  display: 'block',
                  fontWeight: 'bold',
                  textDecoration: 'underline'
                }}
                to={`/meets/eventRanking/${event.id}`}
              >
                Ranking
              </NavLink>
              <NavLink
                style={{
                  display: 'block',
                  fontWeight: 'bold',
                  textDecoration: 'underline'
                }}
                to={`/meets/eventResults/${event.id}`}
              >
                Results
              </NavLink>
            </div>
            <table>
              <thead>
                <tr>
                  <th>AN</th>
                  <th>Name</th>
                  <th>{event.eventType === 'Track' ? '' : 'Atmpt'}</th>
                  <th style={{ textAlign: 'center' }}>
                    {event.eventType === 'Track' ? 'Time' : 'Feet/Inches'}
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {eventResults.map((row) => {
                  const useValue =
                    event.eventType === 'Track'
                      ? row.timeDisplay
                      : row.distanceDisplay
                  let useClass = row.ageMismatchBool
                    ? 'pr-1 age-mismatch'
                    : 'pr-1'
                  if (row.scratchBool) {
                    useClass += ' scratch'
                  }
                  let useTitle = row.ageMismatchBool ? 'Age mismatch' : ''
                  if (row.scratchBool) {
                    useTitle = 'Scratch'
                  }
                  return (
                    <tr key={row.id}>
                      <td className='pr-1'>{row.assignedNumber}</td>
                      <td title={useTitle} className={useClass}>
                        {row.name}
                      </td>
                      <td className='text-center'>
                        {event.eventType === 'Track' ? '' : row.raceId}
                      </td>
                      <td className='pl-4 pr-3'>{useValue}</td>
                      <td style={{ paddingTop: '3px' }}>
                        <MdDeleteForever
                          size='20px'
                          color='red'
                          onClick={() => {
                            const confirm = window.confirm(
                              'Are you sure you want to delete this record?'
                            )
                            if (confirm) {
                              deleteScheduledEventResult(row.id).then(
                                (data) => {
                                  if (data.status === 'error') {
                                    messaging.errorMessage(data.message)
                                    return
                                  }

                                  messaging.successMessage(
                                    'Successfully deleted record'
                                  )
                                  getEventResultsData(event.id)
                                }
                              )
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default EventDataEntryPage
