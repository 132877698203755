import { Formik, Form } from 'formik'
import React from 'react'
import ModalWrapper from '../components/modals/ModalWrapper'
import * as Yup from 'yup'
import MyTextInput from '../components/form/MyTextInput'
import { Button, Divider, Label } from 'semantic-ui-react'
import {
  checkGroupPasskey,
  registerInFirebase
} from '..//firestore/firebaseService'
import SocialLogin from './SocialLogin'
import { useNavigate } from 'react-router-dom'

export default function LoginForm() {
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate('/login')
  }

  return (
    <ModalWrapper
      size='mini'
      header='Register'
      cancelCallback={() => {
        navigate('/')
      }}
    >
      <Formik
        initialValues={{
          email: '',
          password: '',
          displayName: '',
          groupPassword: ''
        }}
        validationSchema={Yup.object({
          displayName: Yup.string().required(),
          email: Yup.string().required().email(),
          password: Yup.string().required(),
          groupPassword: Yup.string().required()
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let groupPasswordValid = false
          console.log(values)
          await checkGroupPasskey(values.groupPassword)
            .then(() => {
              groupPasswordValid = true
            })
            .catch((err) => {
              console.log(err)
            })

          if (!groupPasswordValid) {
            setSubmitting(false)
            setErrors({ auth: 'Invalid Group Password' })
            return
          }

          try {
            await registerInFirebase(values)
            setSubmitting(false)
            navigate('/')
          } catch (error) {
            setErrors({ auth: error.message })
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='displayName' placeholder='Display Name' />
            <MyTextInput name='email' placeholder='Email address' />
            <MyTextInput
              name='password'
              placeholder='Password'
              type='password'
            />{' '}
            <MyTextInput
              name='groupPassword'
              placeholder='Group Password'
              type='password'
            />{' '}
            {errors.auth && (
              <Label
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!dirty || !isValid || isSubmitting}
              type='submit'
              fluid
              size='large'
              color='teal'
              content='Register'
            />
            <Divider horizontal>Or</Divider>
            <Button
              onClick={handleLogin}
              content='Login'
              color='blue'
              size='large'
              fluid
            />
            <Divider horizontal>Or</Divider>
            <SocialLogin />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}
