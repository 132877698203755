import { Formik, Form } from 'formik'
import React from 'react'
import { Button } from 'semantic-ui-react'
import MyTextInput from './form/MyTextInput'
import * as Yup from 'yup'
import { messaging } from '../utils/toast'
import { updatePassword } from '../api/userApi'

export default function ChangePassword() {
  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      validationSchema={Yup.object({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          updatePassword(values)
            .then((data) => {
              messaging.successMessage(data.statusMessage)
              resetForm({
                values: {
                  password: '',
                  confirmPassword: ''
                }
              })
            })
            .catch((err) => {
              messaging.errorMessage(err)
            })
        } catch (error) {
          messaging.errorMessage(error.message)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form className='ui form'>
          <h4>Change Password</h4>
          <input type='password' name='ignore' style={{ display: 'none' }} />
          <MyTextInput
            autoComplete='off'
            type='password'
            name='password'
            placeholder='Password'
          />
          <MyTextInput
            autoComplete='off'
            type='password'
            name='confirmPassword'
            placeholder='Confirm Password'
          />
          <Button
            loading={isSubmitting}
            disabled={isSubmitting || !isValid || !dirty}
            type='submit'
            size='large'
            positive
            content='Update Password'
          />
        </Form>
      )}
    </Formik>
  )
}
