import React from 'react'
import styled from 'styled-components'

const MeetSnapshot = ({ meetInfo }) => {
  return (
    <div>
      <h4>Current Meet: {meetInfo.meetName}</h4>
      <CardContainer>
        <Card>
          <div className='label'>Families</div>
          <div className='value'>{meetInfo.registeredParentCount}</div>
        </Card>
        <Card>
          <div className='label'>Children</div>
          <div className='value'>{meetInfo.registeredChildrenCount}</div>
        </Card>
      </CardContainer>
      <CardContainer>
        <Card>
          <div className='label'>Status</div>
          <div className='value'>{meetInfo.registrationStatus}</div>
        </Card>
        <Card>
          <div className='label'>Offseason?</div>
          <div className='value'>{meetInfo.isOffSeason}</div>
        </Card>
        <Card>
          <div className='label'>Full?</div>
          <div className='value'>{meetInfo.registrationFull}</div>
        </Card>
      </CardContainer>
    </div>
  )
}

export default MeetSnapshot

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  align-items: center;
  border: 1px solid #ccc;
  min-width: 100px;
  margin: 0.25rem;
  > div {
    padding: 0.25rem;
  }
  > div.label {
    font-weight: bold;
  }
  > div.value {
    color: var(--blue-grotto);
    font-weight: 700;
  }
`

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5em;
`
