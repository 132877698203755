import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Button, Container } from 'semantic-ui-react'
import styled from 'styled-components'
import {
  getAllMeets,
  getRegistrationInfo,
  getServerInfo,
  setDefaultMeet,
  toggleCurrentMeetOpenStatus
} from '../api/meetApi'
import { Table } from '../components/form/tables/SortableTable'
import { setCurrentMeetName } from '../store/appSlice'
import { messaging } from '../utils/toast'
import MeetSnapshot from './MeetSnapshot'

const MeetsDashboard = () => {
  const dispatch = useDispatch()
  const [serverInfo, setServerInfo] = useState({ serverTime: '' })
  const [meetInfo, setMeetInfo] = useState({
    isOffSeason: '',
    meetName: '',
    registeredChildrenCount: '',
    registeredParentCount: '',
    registrationDateTime: '',
    registrationStatus: ''
  })
  const [meets, setMeets] = useState([])

  const toggleMeetOpenStatus = () => {
    toggleCurrentMeetOpenStatus().then((data) => {
      messaging.successMessage(data.message)
      setMeetInfo({ ...meetInfo, registrationStatus: data.value })
    })
  }

  const fetchMeets = () => {
    getAllMeets().then((data) => {
      setMeets(
        data.sort((a, b) => {
          return b.id - a.id
        })
      )
    })
  }

  const fetchServerInfo = () => {
    getServerInfo().then((data) => {
      setServerInfo(data)
    })
  }

  const fetchMeetAndRegistrationInfo = () => {
    getRegistrationInfo().then((data) => {
      setMeetInfo(data)
    })
  }

  useEffect(() => {
    fetchMeets()
    fetchServerInfo()
    fetchMeetAndRegistrationInfo()

    const intvl = setInterval(() => {
      fetchMeetAndRegistrationInfo()
    }, 60000)

    return () => {
      clearInterval(intvl)
    }
  }, [])

  const MakeDefaultLink = ({ row }) => {
    // console.log(row)
    return (
      <NavLink
        title='Set Active Meet To Administer'
        to={'#'}
        onClick={(e) => {
          e.preventDefault()
          setDefaultMeet(row.values.id).then((data) => {
            if (data.status === 'success') {
              messaging.successMessage(
                'Meet ' + row.values.name + ' is now the default meet'
              )
              dispatch(setCurrentMeetName(row.values.name))
              fetchMeets()
              fetchMeetAndRegistrationInfo()
            }
          })
        }}
      >
        Set Active
      </NavLink>
    )
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: '',
      accessor: 'id',
      Cell: MakeDefaultLink
    }
  ]

  return (
    <div style={{ height: 'calc(100vh - 100px)', overflow: 'auto' }}>
      <div className='mb-1'>
        <strong>Current Server time: </strong>
        <span>{serverInfo.serverTime}</span>
      </div>
      <div className='mb-1'>
        <strong>Meet Reg. Begins: </strong>
        <span>{meetInfo.registrationDateTime}</span>
      </div>
      <Container className='reg-status'>
        {meetInfo && <MeetSnapshot meetInfo={meetInfo} />}
        <div className='grid-item-button mt-2'>
          <Button
            onClick={() => {
              toggleMeetOpenStatus()
            }}
            color='green'
            content='Toggle Reg. Open Status'
          />
        </div>
      </Container>
      <Styles>
        <Table columns={columns} data={meets} />
      </Styles>
    </div>
  )
}

export default MeetsDashboard

const Styles = styled.div`
  display: none;
  padding: 1rem;
  table {
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      padding-right: 1rem;
      text-align: left;
    }
  }
`
