import React, { useState } from 'react'
import { Container, Segment } from 'semantic-ui-react'
import { Credential } from '../components/Credential'
import SearchContacts from '../components/SearchContacts'

const ContactsPage = () => {
  const [contact, setContact] = useState(null)

  return (
    <div>
      <h1>Contacts</h1>
      <SearchContacts setContact={setContact} />
      {contact && (
        <Container className='mt-1'>
          <h1 className='mt-2'>{contact.firstName + ' ' + contact.lastName}</h1>
          <Segment className='w-80'>
            <Credential contactId={contact.id} />
          </Segment>
        </Container>
      )}
    </div>
  )
}

export default ContactsPage
