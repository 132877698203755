import React from 'react'
import styled from 'styled-components'

const NSF = () => {
  return (
    <RestrictedAccess>
      You do not have the required access to view this content.
    </RestrictedAccess>
  )
}

export default NSF

const RestrictedAccess = styled.div`
  margin: 20px;
  color: #990000;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  width: 100%;
`
