import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCq0s-QJCwbJPDkNyKri1cHIu3QQpceBiM',
  authDomain: 'mgmnt-console.firebaseapp.com',
  projectId: 'mgmnt-console',
  storageBucket: 'mgmnt-console.appspot.com',
  messagingSenderId: '50919332620',
  appId: '1:50919332620:web:b4858d37dc6adbda1ec729'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.firestore()
export const auth = firebase.auth()
export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const storage = firebase.storage()

export default firebase
