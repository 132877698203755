import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modals',
  initialState: null,
  reducers: {
    openModal: (state, action) => {
      return action.payload
    },
    closeModal: (state) => {
      return null
    }
  }
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
