import React from 'react'
import { FaBars } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { setSideBarOpen } from '../store/appSlice'
import { logOutUser } from '../store/userSlice'
import { messaging } from '../utils/toast'

const Header = ({ currentMeetName }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const handleLogin = () => {
    navigate('/login')
  }
  const handleLogout = () => {
    messaging.successMessage('You have successfully logged out!')
    dispatch(logOutUser())
    navigate('/')
  }
  return (
    <header className='main-header'>
      <div id='menuBars'>
        <FaBars
          style={{ fontSize: '30px' }}
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setSideBarOpen(true))
          }}
        />
      </div>
      <h4>HSTrack</h4>
      {!user.loggedIn && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button onClick={handleLogin}>Login</Button>
        </div>
      )}
      {user.loggedIn && (
        <>
          <p className='user-formal-name'>
            {user.firstName + ' ' + user.lastName} <br />
            {currentMeetName && <>Current meet: {currentMeetName}</>}
          </p>
          <Button onClick={handleLogout}>Logout</Button>
        </>
      )}
    </header>
  )
}

export default Header
