import React, { useEffect, useState } from 'react'
import { Button, Input } from 'semantic-ui-react'
import {
  getContactWebCredentials,
  updateContactWebsitePassword
} from '../api/adminApi'
import { messaging } from '../utils/toast'
import Loading from './Loading'

export const Credential = ({ contactId, onSavePasswordSuccess }) => {
  const [password, setPassword] = useState('')
  const [cred, setCred] = useState(null)

  useEffect(() => {
    let isMounted = true
    if (contactId) {
      getContactWebCredentials(contactId).then((data) => {
        if (isMounted) setCred(data.data)
      })
    }
    return () => {
      isMounted = false
    }
  }, [contactId])

  const handlePasswordReset = (e) => {
    e.preventDefault()
    if (password && password !== '') {
      updateContactWebsitePassword(contactId, password).then((data) => {
        if (data.status === 'success') {
          messaging.successMessage('Password Updated!')
          if (onSavePasswordSuccess) {
            onSavePasswordSuccess()
          }
          setPassword('')
        } else {
          messaging.errorMessage(data.message)
        }
      })
    }
  }

  if (!contactId) {
    return <></>
  }

  if (!cred) {
    return <Loading />
  }

  return (
    <>
      <h3>Website Credentials</h3>
      <div className='mt-1'>
        <h3 className='mb-1'>Username</h3>
        <p className='mb-2'>{cred.username}</p>
        <h3 className='mb-1'>Email address</h3>
        <p className='mb-1'>{cred.emailAddress}</p>
      </div>
      <form className='mt-1'>
        <h5>Reset password</h5>
        <Input
          type='password'
          name='password'
          id='password'
          placeholder='Enter New Password'
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          value={password}
        />
        <Button className='mx-1' color='green' onClick={handlePasswordReset}>
          Submit
        </Button>
      </form>
    </>
  )
}
