import api from './axiosConfig'

const rootMeetAdminUrl = '/Meet_admin'
const rootMeetUrl = '/MeetData'

export const getCurrentMeet = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetUrl + '/current_meet')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const setDefaultMeet = (meetId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/set_default_meet/' + meetId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getRegistrationInfo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetUrl + '/registration_info')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getServerInfo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetUrl + '/server_info')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getAllMeets = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetUrl + '/meet')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getMeet = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetUrl + '/meet/' + id)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const createOrUpdateChild = (child) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootMeetAdminUrl + '/create_or_update_child',
        child
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const setParentsPaid = (parentsId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/set_parents_paid/' + parentsId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getRegisteredParents = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetAdminUrl + '/get_registered_parents')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getChild = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetAdminUrl + '/get_child/' + id)
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getChildParticipants = (meetId) => {
  return new Promise(async (resolve, reject) => {
    const useParams = meetId ? '/' + meetId : ''
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/child_participants' + useParams
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getChildParticipantResultsSummary = (meetId) => {
  return new Promise(async (resolve, reject) => {
    const useParams = meetId ? '/' + meetId : ''
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/child_participants_with_summary' + useParams
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getChildParticipant = (participantId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/child_participant/' + participantId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getChildParticipantByAn = (an, meetId) => {
  return new Promise(async (resolve, reject) => {
    const useParams = an + (meetId ? '/' + meetId : '')
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/child_participant_by_an/' + useParams
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getChildParticipantActivities = (participantId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/child_participant_activities/' + participantId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getScheduledEvents = (id) => {
  return new Promise(async (resolve, reject) => {
    const useId = id ? '/' + id : ''
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/scheduled_meet_events' + useId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getScheduledEvent = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/scheduled_meet_event/' + id
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getScheduledEventRoster = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl + '/get_scheduled_event_child_participants/' + id
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getScheduledEventResults = (scheduledEventId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl +
          '/get_scheduled_meet_event_results/' +
          scheduledEventId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const getScheduledEventResultsWithSuggestedRanking = (
  scheduledEventId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl +
          '/get_suggested_ranking_for_event/' +
          scheduledEventId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const setEventPrintedStatus = (scheduledEventId, printed) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl +
          '/set_event_printed/' +
          scheduledEventId +
          '/' +
          printed
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const setEventRankedStatus = (scheduledEventId, ranked) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        rootMeetAdminUrl +
          '/set_event_ranked/' +
          scheduledEventId +
          '/' +
          ranked
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const updateRankingsForEvent = (scheduledEventId, rankedResults) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootMeetAdminUrl + '/update_rankings_for_event/' + scheduledEventId,
        rankedResults
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const deleteScheduledEventResult = (scheduledEventParticipantId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(
        rootMeetAdminUrl +
          '/delete_scheduled_meet_event_results/' +
          scheduledEventParticipantId
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const addChildParticipantEventRecord = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootMeetAdminUrl + '/add_scheduled_event_child_participant',
        data
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const toggleCurrentMeetOpenStatus = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(rootMeetAdminUrl + '/meet_toggle_open_status')
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}

export const assignParticipantNumber = (participantId, assignedNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        rootMeetAdminUrl +
          '/assign_child_participant_number_for_meet/' +
          participantId +
          '/' +
          assignedNumber
      )
      resolve(res.data)
    } catch (error) {
      reject(error)
    }
  })
}
