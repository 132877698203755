import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import { getScheduledEvent, getScheduledEventRoster } from '../api/meetApi'
import styled from 'styled-components'
import Loading from '../components/Loading'
import { Table } from '../components/form/tables/SortableTable'
import { useParams } from 'react-router-dom'

const EventRosterPage = () => {
  const [event, setEvent] = useState({ id: null, eventType: '' })
  const [participants, setParticipants] = useState(null)

  const params = useParams()

  useEffect(() => {
    if (params.id) {
      getScheduledEvent(params.id).then((data) => {
        const body = document.getElementsByTagName('body')
        if (body && body.length > 0) {
          body[0].classList.add('printing')
        }
        setEvent(data)
      })
      getScheduledEventRoster(params.id).then((data) => {
        setParticipants(data.data)
      })
    }
  }, [params.id])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Participants',
        columns: [
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'AN',
            accessor: 'assignedNumber'
          }
        ]
      },
      {
        Header: event.eventType === 'Track' ? 'Stats' : 'Attempts',
        columns: [
          {
            Header: event.eventType === 'Track' ? 'Heat' : '1st',
            accessor: 'bc1'
          },
          {
            Header: event.eventType === 'Track' ? 'Lane' : '2nd',
            accessor: 'bc2'
          },
          {
            Header: event.eventType === 'Track' ? 'Time' : '3rd',
            accessor: 'bc3'
          }
        ]
      }
    ],
    [event]
  )

  if (event === null || event.id === null || participants === null) {
    return <Loading />
  }

  return ReactDom.createPortal(
    <div style={{ padding: '2rem', width: '7.25in' }}>
      <h2>
        {event.id} - {event.name} - Ages {event.minimumAge}-{event.maximumAge}{' '}
        for {event.gender}
      </h2>
      <Styles>
        <Table columns={columns} data={participants} />
      </Styles>
    </div>,
    document.getElementById('toPrint')
  )
}

export default EventRosterPage

const Styles = styled.div`
  padding: 1rem;
  table {
    width: 100%;
    border-spacing: 0;
    th,
    td {
      border: 1px solid black;
      margin: 0;
      padding: 0.5rem;
      padding-right: 1rem;
      text-align: left;
    }
  }
`
