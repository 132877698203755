import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './views/Home'
import RegisterForm from './views/RegisterForm'
import ProfileForm from './views/ProfileForm'
import Header from './components/Header'
import Loading from './components/Loading'
import { ToastContainer } from 'react-toastify'
import LoginForm from './views/LoginForm'
import NotFound from './views/NotFound'
import PrivateRoute from './PrivateRoute'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading, setCurrentMeetName } from './store/appSlice'
import EmailMonitor from './views/EmailMonitor'
import ContactsPage from './views/ContactsPage'
import SideMenu from './components/SideMenu'
import ModalManager from './components/modals/ModalManager'
import MeetsDashboard from './views/MeetsDashboard'
import ParentsPage from './views/ParentsPage'
import RegisteredParents from './views/RegisteredParents'
import EventDataEntryPage from './views/EventDataEntryPage'
import EventResultsPage from './views/EventResultsPage'
import EventsStatusPage from './views/EventsStatusPage'
import EventsPage from './views/EventsPage'
import EventPrintResultsPage from './views/EventPrintResultsPage'
import EventRankPage from './views/EventRankPage'
import { getCurrentMeet } from './api/meetApi'
import ParticipantPage from './views/ParticipantPage'
import ParticipantsPage from './views/ParticipantsPage'
import ChildPage from './views/ChildPage'
import EventRosterPage from './views/EventRosterPage'
import { tokenCurrent } from './utils/userUtils'
import { logOutUser } from './store/userSlice'

function App() {
  const app = useSelector((state) => state.app)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsLoading(false))

    if (!tokenCurrent()) {
      dispatch(logOutUser())
    }

    getCurrentMeet().then((data) => {
      dispatch(setCurrentMeetName(data.name))
    })
  }, [dispatch, app])

  if (app.isLoading) {
    return <Loading />
  }

  return (
    <Router>
      <ModalManager />
      <ToastContainer
        position='top-center'
        draggable={false}
        closeOnClick={true}
        autoClose={2500}
      />
      <div className='app-wrapper'>
        <Header currentMeetName={app.currentMeetName} />
        <div className='app-inner'>
          <SideMenu />
          <div className='main-content'>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/login' element={<LoginForm />} />
              <Route path='/register' element={<RegisterForm />} />
              <Route
                path='/profile'
                element={<PrivateRoute component={ProfileForm} />}
              />
              <Route
                path='/pendingEmails'
                element={<PrivateRoute component={EmailMonitor} />}
              />
              <Route
                path='/contacts'
                element={<PrivateRoute component={ContactsPage} />}
              />
              <Route
                path='/child'
                element={<PrivateRoute component={ChildPage} />}
              />
              <Route
                path='/child/:id'
                element={<PrivateRoute component={ChildPage} />}
              />
              <Route
                path='/parents'
                element={<PrivateRoute component={ParentsPage} />}
              />
              <Route
                path='/parents/:id'
                element={<PrivateRoute component={ParentsPage} />}
              />
              <Route
                path='/registeredParents'
                element={<PrivateRoute component={RegisteredParents} />}
              />
              <Route
                path='/meets/dashboard'
                element={<PrivateRoute component={MeetsDashboard} />}
              />
              <Route
                exact
                path='/meets/eventDataEntry'
                element={<PrivateRoute component={EventDataEntryPage} />}
              />
              <Route
                path='/meets/eventDataEntry/:scheduledEventId'
                element={<PrivateRoute component={EventDataEntryPage} />}
              />
              <Route
                path='/meets/eventResults/:scheduledEventId'
                element={<PrivateRoute component={EventResultsPage} />}
              />
              <Route
                path='/meets/eventRanking/:scheduledEventId'
                element={<PrivateRoute component={EventRankPage} />}
              />
              <Route
                path='/meets/eventPrintResults/:scheduledEventId'
                element={<PrivateRoute component={EventPrintResultsPage} />}
              />
              <Route
                path='/meets/participants'
                element={<PrivateRoute component={ParticipantsPage} />}
              />
              <Route
                path='/meets/participants/:id'
                element={<PrivateRoute component={ParticipantPage} />}
              />
              <Route
                path='/meets/eventsStatus'
                element={<PrivateRoute component={EventsStatusPage} />}
              />
              <Route
                path='/meets/eventsListing'
                element={<PrivateRoute component={EventsPage} />}
              />
              <Route
                path='/meets/eventRoster/:id'
                element={<PrivateRoute component={EventRosterPage} />}
              />
              <Route
                path='/pendingEmails'
                element={<PrivateRoute component={EmailMonitor} />}
              />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  )
}

export default App
