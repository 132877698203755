import React, { useEffect, useState } from 'react'
import { MdPrint } from 'react-icons/md'
import { Link, NavLink, useParams } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { getScheduledEvent, getScheduledEventResults } from '../api/meetApi'
import { messaging } from '../utils/toast'

const EventResultsPage = () => {
  const [event, setEvent] = useState(null)
  const [eventResults, setEventResults] = useState([])

  const params = useParams()

  const getEventResultsData = (eventId) => {
    getScheduledEventResults(eventId).then((data) => {
      const resultsData = data.data
      resultsData.sort((a, b) => {
        let useA = '5'
        let useB = '5'
        if (
          a.overallPosition !== '0' &&
          a.overallPosition !== '' &&
          a.overallPosition != null
        )
          useA = a.overallPosition
        if (
          b.overallPosition !== '0' &&
          b.overallPosition !== '' &&
          b.overallPosition != null
        )
          useB = b.overallPosition
        return useA - useB
      })
      setEventResults(resultsData)
    })
  }

  useEffect(() => {
    const eventId = params.scheduledEventId

    let isMounted = true
    if (eventId && isMounted) {
      getScheduledEvent(eventId).then((data) => {
        if (data.status === 'error') {
          messaging.errorMessage(data.message)
          return
        } else {
          setEvent(data)
        }
      })
      getEventResultsData(eventId)
    }

    return () => {
      isMounted = false
    }
  }, [params.scheduledEventId])

  return (
    <div>
      {event && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4 className='my-1'>{`
        ${event.id} - ${event.name} - ${event.minimumAge} - ${event.maximumAge} for ${event.gender}
      `}</h4>
          <NavLink
            className='p-3'
            to={'/meets/eventPrintResults/' + event.id}
            target='_blank'
          >
            <MdPrint size='30px' />
          </NavLink>
          <Button
            content='Entry'
            as={Link}
            to={'/meets/eventDataEntry/' + event.id}
          />
          <Button
            content='Rank'
            as={Link}
            to={'/meets/eventRanking/' + event.id}
          />
        </div>
      )}
      <div className='results-data'>
        {event && eventResults && eventResults.length > 0 && (
          <div className='event-results my-1'>
            <table>
              <thead>
                <tr>
                  <th>AN</th>
                  <th>Name</th>
                  <th>
                    {event.eventType === 'Field' ||
                    event.name.indexOf('stacle') > 0
                      ? 'Atmpt'
                      : ''}
                  </th>
                  <th className='pl-3'>
                    {event.eventType === 'Track' ? 'Time' : 'Feet/Inches'}
                  </th>
                  <th>Overall Pos.</th>
                  <th className='pl-3 text-center'>Points</th>
                </tr>
              </thead>
              <tbody>
                {eventResults.map((row) => {
                  const useValue =
                    event.eventType === 'Track'
                      ? row.timeDisplay
                      : row.distanceDisplay
                  let useClass = row.ageMismatchBool
                    ? 'pr-1 age-mismatch'
                    : 'pr-1'
                  if (row.scratchBool) {
                    useClass += ' scratch'
                  }
                  let useTitle = row.ageMismatchBool ? 'Age mismatch' : ''
                  if (row.scratchBool) {
                    useTitle = 'Scratch'
                  }
                  return (
                    <tr key={row.id}>
                      <td className='pr-1'>{row.assignedNumber}</td>
                      <td title={useTitle} className={useClass}>
                        {row.name}
                      </td>
                      <td className='text-center'>
                        {event.eventType === 'Field' ||
                        event.name.indexOf('stacle') > 0
                          ? row.attempt
                          : ''}
                      </td>
                      <td className='pl-2 pr-3'>{useValue}</td>
                      <td className='text-center'>{row.overallPosition}</td>
                      <td className='pl-3 text-center'>{row.points}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default EventResultsPage
