import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASEURL
})

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('accessJWT')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default instance
