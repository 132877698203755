import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { closeModal } from '../../store/modalSlice'
import ModalWrapper from './ModalWrapper'

const ExtendSessionModal = () => {
  const dispatch = useDispatch()
  return (
    <ModalWrapper>
      <h1>Modal</h1>
      <Button
        content='Close'
        onClick={() => {
          dispatch(closeModal())
        }}
      />
    </ModalWrapper>
  )
}

export default ExtendSessionModal
