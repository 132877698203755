import React, { useEffect, useState } from 'react'
import { MdPrint, MdRefresh } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { Button, Checkbox, Dropdown } from 'semantic-ui-react'
import { getScheduledEvents, setEventPrintedStatus } from '../api/meetApi'
import Ably from '../api/ablyApi'

const EventsStatusPage = () => {
  const [events, setEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [useFilter, setUseFilter] = useState(1) // 0: all, 1:
  const [showOnlyWithResults, setShowOnlyWithResults] = useState(true)

  const options = [
    { key: 1, text: 'Show All', value: 1 },
    { key: 2, text: 'All Ranked', value: 2 },
    { key: 3, text: 'All Printed', value: 3 },
    { key: 4, text: 'Ranked not Printed', value: 4 },
    { key: 5, text: 'Printed not Ranked', value: 5 },
    { key: 6, text: 'Not Ranked', value: 6 },
    { key: 7, text: 'Not Printed', value: 7 }
  ]

  const updatesChannel = Ably.channels.get('update-events')

  const handleFilter = (e, { value }) => {
    setUseFilter(value)
  }

  const doGetScheduledEvents = () => {
    getScheduledEvents().then((data) => {
      setEvents(data)
      setFilteredEvents(data)
    })
  }

  useEffect(() => {
    console.log('setting up')
    updatesChannel.subscribe('update-events', (resp) => {
      console.log(resp)
      doGetScheduledEvents()
    })

    return () => {
      updatesChannel.unsubscribe()
    }
  }, [updatesChannel])

  useEffect(() => {
    doGetScheduledEvents()
  }, [])

  const markEventPrinted = (eventId) => {
    events.forEach((event) => {
      if (event.id === eventId) {
        event.printed = event.printed === '0' ? '1' : '0'
        setEventPrintedStatus(eventId, event.printed)
      }
    })
    setFilteredEvents([...events])
  }

  return (
    <div>
      <div
        style={{
          width: '100%',
          backgroundColor: 'white',
          position: 'relative'
        }}
      >
        <div className='pb-2' style={{ display: 'flex', alignItems: 'center' }}>
          <h4 className='pr-2'>Events Status</h4>
          <Button
            size='mini'
            color='green'
            title='Refresh Results'
            onClick={() => {
              doGetScheduledEvents()
            }}
          >
            <MdRefresh color='white' size='20px' />
          </Button>
          <Dropdown
            className='ml-2'
            size='small'
            onChange={handleFilter}
            options={options}
            placeholder='Filter'
            selection
            clearable
            value={useFilter}
          />
          <Checkbox
            size='mini'
            className='pl-2 checkbox-label-left'
            name='showOnlyWithResults'
            label='Has Results?'
            checked={showOnlyWithResults}
            onChange={(e) => {
              setShowOnlyWithResults(!showOnlyWithResults)
            }}
          />
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
          height: 'calc(100vh - 170px)'
        }}
      >
        <div className='results'>
          <table>
            <thead>
              <tr>
                <th>Event</th>
                <th className='pr-4'>Ranked</th>
                <th>Printed</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.length > 0 &&
                filteredEvents.map((event) => {
                  let shouldShow = true
                  switch (useFilter) {
                    case 1:
                      shouldShow = true
                      break
                    case 2:
                      shouldShow = event.ranked === '1'
                      break
                    case 3:
                      shouldShow = event.printed === '1'
                      break
                    case 4:
                      shouldShow = event.ranked === '1' && event.printed === '0'
                      break
                    case 5:
                      shouldShow = event.printed === '1' && event.ranked === '0'
                      break
                    case 6:
                      shouldShow = event.ranked === '0'
                      break
                    case 7:
                      shouldShow = event.printed === '0'
                      break
                    default:
                      shouldShow = true
                  }

                  if (showOnlyWithResults) {
                    shouldShow = event.hasResults === 'Yes' && shouldShow
                  }

                  return (
                    <tr
                      style={{ display: shouldShow ? '' : 'none' }}
                      key={event.id}
                    >
                      <td className='pr-4'>
                        <NavLink
                          title={
                            event.hasResults === 'Yes'
                              ? ''
                              : 'No results processed yet'
                          }
                          style={{
                            textDecoration:
                              event.hasResults === 'Yes'
                                ? 'none'
                                : 'line-through'
                          }}
                          to={'/meets/eventResults/' + event.id}
                        >{`${event.id} - ${event.name} - ${event.minimumAge} - ${event.maximumAge} for ${event.gender}`}</NavLink>
                      </td>
                      <td>{event.ranked === '1' ? 'Yes' : 'No'}</td>
                      <td style={{ paddingTop: '4px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            style={{
                              display: 'inline-block',
                              marginRight: '20px'
                            }}
                            type='checkbox'
                            checked={event.printed === '1'}
                            onChange={() => {
                              markEventPrinted(event.id)
                            }}
                          />
                        </div>
                      </td>
                      <td
                        style={{ paddingLeft: '7px', verticalAlign: 'middle' }}
                      >
                        <NavLink
                          style={{ position: 'relative', top: '3px' }}
                          to={'/meets/eventPrintResults/' + event.id}
                          target='_blank'
                        >
                          {<MdPrint size={'20px'} />}
                        </NavLink>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default EventsStatusPage
