import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Dropdown } from 'semantic-ui-react'
import { getScheduledEvents } from '../api/meetApi'
import { NavLink } from 'react-router-dom'
import { Table } from '../components/form/tables/SortableTable'

const EventsSummaryPage = () => {
  const [events, setEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [genderFilter, setGenderFilter] = useState(0)
  const [ageFilter, setAgeFilter] = useState(0)

  const genderOptions = [
    { key: 1, text: 'Show All', value: 1 },
    { key: 2, text: 'Girls', value: 2 },
    { key: 3, text: 'Boys', value: 3 }
  ]

  const ageOptions = [
    { key: 1, text: 'Show All', value: 1 },
    { key: 2, text: '3-4', value: 2 },
    { key: 3, text: '5-6', value: 3 },
    { key: 4, text: '7-8', value: 4 },
    { key: 5, text: '9-10', value: 5 },
    { key: 6, text: '11-12', value: 6 },
    { key: 7, text: '13-14', value: 7 },
    { key: 8, text: '15-18', value: 8 }
  ]

  const RosterLink = ({ row }) => {
    // console.log(row)
    return (
      <NavLink
        title='Go to event roster'
        to={'/meets/eventRoster/' + row.values.id}
        target='_blank'
      >
        Roster
      </NavLink>
    )
  }

  const ResultsLink = ({ row }) => {
    // console.log(row)
    if (row.original.hasResults === 'Yes') {
      return (
        <NavLink
          title='Go to event results'
          to={'/meets/eventResults/' + row.values.id}
          target='_blank'
        >
          Results
        </NavLink>
      )
    } else {
      return <>&nbsp;</>
    }
  }

  const AgeTD = ({ row }) => {
    // console.log(row)

    return (
      <span>
        {row.original.minimumAge} - {row.original.maximumAge}
      </span>
    )
  }

  const handleGenderFilter = (e, { value }) => {
    setGenderFilter(value)
  }

  const handleAgeFilter = (e, { value }) => {
    setAgeFilter(value)
  }

  useEffect(() => {
    getScheduledEvents().then((data) => {
      setEvents(data)
      setFilteredEvents(data)
    })
  }, [])

  const filterEvents = useCallback(() => {
    const filtered = events.filter((part) => {
      let retValue = true

      switch (genderFilter) {
        case 1:
          retValue = retValue && true
          break
        case 2:
          retValue = retValue && part.gender === 'Girls'
          break
        case 3:
          retValue = retValue && part.gender === 'Boys'
          break
        default:
          retValue = retValue && true
      }

      switch (ageFilter) {
        case 1:
          retValue = retValue && true
          break
        case 2:
          retValue = retValue && part.ageGroup === '3-4'
          break
        case 3:
          retValue = retValue && part.ageGroup === '5-6'
          break
        case 4:
          retValue = retValue && part.ageGroup === '7-8'
          break
        case 5:
          retValue = retValue && part.ageGroup === '9-10'
          break
        case 6:
          retValue = retValue && part.ageGroup === '11-12'
          break
        case 7:
          retValue = retValue && part.ageGroup === '13-14'
          break
        case 8:
          retValue = retValue && part.ageGroup === '15-18'
          break
        default:
          retValue = retValue && true
      }

      return retValue
    })
    setFilteredEvents(filtered)
  }, [events, genderFilter, ageFilter])

  useEffect(() => {
    filterEvents()
  }, [filterEvents])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Events',
        columns: [
          {
            Header: 'Id',
            accessor: 'id'
          },
          {
            Header: 'Event Name',
            accessor: 'name'
          },
          {
            Header: 'Gender',
            accessor: 'gender'
          },
          {
            Header: 'Age Group',
            Cell: AgeTD
          },
          {
            Header: '',
            accessor: 'roster-id',
            Cell: RosterLink
          },
          {
            Header: '',
            accessor: 'result-id',
            Cell: ResultsLink
          }
        ]
      }
    ],
    []
  )

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <>
          <Dropdown
            size='10px'
            className='ml-2'
            onChange={handleGenderFilter}
            options={genderOptions}
            placeholder='Gender'
            selection
            clearable
            value={genderFilter}
          />
          <Dropdown
            size='10px'
            className='ml-2'
            onChange={handleAgeFilter}
            options={ageOptions}
            placeholder='Age Group'
            selection
            clearable
            value={ageFilter}
          />
        </>
      </div>
      <div
        style={{
          overflow: 'auto',
          height: 'calc(100vh - 125px)'
        }}
      >
        <Styles>
          <Table columns={columns} data={filteredEvents} />
        </Styles>
      </div>
    </div>
  )
}

export default EventsSummaryPage

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      padding-right: 1rem;
      text-align: left;
    }
    tr:first-child th:first-child {
      display: none;
    }
  }
`
