import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Input } from 'semantic-ui-react'
import { addChildParticipantEventRecord } from '../../api/meetApi'
import { messaging } from '../../utils/toast'

const EventDataEntryForm = ({
  childParticipant,
  event,
  onSuccess,
  onCancel,
  soundAlert
}) => {
  const [placeholder, setPlaceholder] = useState('Time')
  const [score, setScore] = useState('')
  const [scratch, setScratch] = useState(false)
  const [attempt, setAttempt] = useState(1)

  const inputRef = useRef()
  const attemptRef = useRef()

  useEffect(() => {
    if (attemptRef.current) {
      attemptRef.current.inputRef.current.value = '1'
    }
    inputRef.current.inputRef.current.value = ''
    inputRef.current.focus()
    setPlaceholder(event.eventType === 'Track' ? 'Time' : 'Feet/Inches')
    setScore('')
    setAttempt(1)
    setScratch(false)
  }, [event, childParticipant])

  const handleKeyDownTabToAttempt = (e) => {
    if (e.keyCode === 13) {
      attemptRef.current.focus()
      attemptRef.current.select()
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
    if ([110].includes(e.keyCode)) {
      e.preventDefault()
      return false
    }
    return true
  }

  const handleInputChange = () => {
    const strVal = inputRef.current.inputRef.current.value
    if (strVal.length > 7) {
      inputRef.current.inputRef.current.value = strVal.substring(0, 7)
    } else {
      setScore(strVal)
    }
  }

  const handleAttemptInputChange = () => {
    const strVal = attemptRef.current.inputRef.current.value
    if (strVal.length > 1) {
      attemptRef.current.inputRef.current.value = strVal.substring(0, 1)
    } else {
      setAttempt(strVal)
    }
    if (!['1', '2', '3'].includes(strVal)) {
      setAttempt(1)
      attemptRef.current.inputRef.current.value = 1
      attemptRef.current.inputRef.current.select()
    }

    attemptRef.current.inputRef.current.select()
  }

  const attemptSelectText = (e) => {
    e.target.select()
  }

  const handleSubmit = () => {
    if (!score || score === '' || score === 0) {
      messaging.errorMessage('Please enter a valid result')
      soundAlert()
      inputRef.current.focus()
      return
    }
    const minAge = parseInt(event.minimumAge)
    const maxAge = parseInt(event.maximumAge)
    const ageMismatch =
      childParticipant.age < minAge || childParticipant.age > maxAge
    const data = {
      childParticipantId: childParticipant.id,
      ageMismatch: ageMismatch,
      score,
      scheduledEventId: event.id,
      eventType: event.eventType,
      attempt,
      scratch
    }
    addChildParticipantEventRecord(data).then((data) => {
      if (data.status === 'error') {
        soundAlert()
        messaging.errorMessage(data.error.message)
      } else {
        onSuccess()
      }
    })
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }} className='my-1'>
        <Input
          size='mini'
          onKeyDown={handleKeyDownTabToAttempt}
          ref={inputRef}
          name='time'
          type='number'
          placeholder={placeholder}
          className='mr-1'
          onChange={handleInputChange}
        />
        <div
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <label>Attempt: &nbsp;</label>
          <Input
            style={{ width: '75px', marginBottom: '3px' }}
            size='mini'
            onKeyDown={handleKeyDown}
            ref={attemptRef}
            name='attempt'
            type='number'
            placeholder='Attempt'
            className='mr-1'
            onChange={handleAttemptInputChange}
            onClick={attemptSelectText}
          />
          <Checkbox
            className='checkbox-label-left'
            name='scratch'
            label='Scratch?'
            checked={scratch}
            onChange={(e) => {
              setScratch(!scratch)
            }}
          />
        </div>
      </div>
      <Button size='tiny' color='green' onClick={handleSubmit} content='Ok' />
      <Button
        color='red'
        size='tiny'
        onClick={() => {
          onCancel()
        }}
        content='Cancel'
      />
    </div>
  )
}

export default EventDataEntryForm
