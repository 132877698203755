import React, { useCallback, useEffect, useState } from 'react'
import {
  assignParticipantNumber,
  getChildParticipantResultsSummary,
  getChildParticipants
} from '../api/meetApi'
import styled from 'styled-components'
import { Dropdown, Input } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { MdClear } from 'react-icons/md'
import { Table } from '../components/form/tables/SortableTable'
import { ParticipantDivisionLeaders } from '../components/ParticipantDivisionLeaders'
import { messaging } from '../utils/toast'

const ParticipantsPage = () => {
  const [showWithResults, setShowWithResults] = useState(true)
  const [participants, setParticipants] = useState([])
  const [filteredParticipants, setFilteredParticipants] = useState([])
  const [genderFilter, setGenderFilter] = useState(0)
  const [ageFilter, setAgeFilter] = useState(0)
  const [assignedNumber, setAssignedNumber] = useState('')
  const [showDivisionLeaders, setShowDivisionLeaders] = useState(false)
  const [dirty, setDirty] = useState(0)

  const genderOptions = [
    { key: 1, text: 'Show All', value: 1 },
    { key: 2, text: 'Girls', value: 2 },
    { key: 3, text: 'Boys', value: 3 }
  ]

  const ageOptions = [
    { key: 1, text: 'Show All', value: 1 },
    { key: 2, text: '3-4', value: 2 },
    { key: 3, text: '5-6', value: 3 },
    { key: 4, text: '7-8', value: 4 },
    { key: 5, text: '9-10', value: 5 },
    { key: 6, text: '11-12', value: 6 },
    { key: 7, text: '13-14', value: 7 },
    { key: 8, text: '15+', value: 8 }
  ]

  const handleGenderFilter = (e, { value }) => {
    setGenderFilter(value)
  }

  const handleAgeFilter = (e, { value }) => {
    setAgeFilter(value)
  }

  const ParticipantLink = ({ row }) => {
    return (
      <NavLink
        title='Go to participant record'
        to={'/meets/participants/' + row.values.id}
      >
        {row.values.id}
      </NavLink>
    )
  }

  useEffect(() => {
    if (showWithResults) {
      getChildParticipantResultsSummary().then((data) => {
        setParticipants(data)
        setFilteredParticipants(data)
      })
    } else {
      getChildParticipants().then((data) => {
        setParticipants(data)
        setFilteredParticipants(data)
      })
    }
  }, [dirty, showWithResults])

  const filterParticipants = useCallback(() => {
    const useParticipants = participants || []
    const filtered = useParticipants.filter((part) => {
      let retValue = true
      if (assignedNumber !== '') {
        // Just return as it is an explicit filter
        return assignedNumber === part.assigned_number
      }

      switch (genderFilter) {
        case 1:
          retValue = retValue && true
          break
        case 2:
          retValue = retValue && part.gender === 'Female'
          break
        case 3:
          retValue = retValue && part.gender === 'Male'
          break
        default:
          retValue = retValue && true
      }

      switch (ageFilter) {
        case 1:
          retValue = retValue && true
          break
        case 2:
          retValue = retValue && part.age_group === '3-4'
          break
        case 3:
          retValue = retValue && part.age_group === '5-6'
          break
        case 4:
          retValue = retValue && part.age_group === '7-8'
          break
        case 5:
          retValue = retValue && part.age_group === '9-10'
          break
        case 6:
          retValue = retValue && part.age_group === '11-12'
          break
        case 7:
          retValue = retValue && part.age_group === '13-14'
          break
        case 8:
          retValue = retValue && part.age_group === '15+'
          break
        default:
          retValue = retValue && true
      }

      return retValue
    })
    setFilteredParticipants(filtered)
  }, [participants, assignedNumber, genderFilter, ageFilter])

  const AssignedNumber = (meta) => {
    const [participantSelected, setParticipantSelected] = useState(false)
    const [inputRef, setInputRef] = useState(null)
    const [value, setValue] = useState(null)

    let createInputRef = (elem) => {
      setInputRef(elem)
    }

    useEffect(() => {
      if (inputRef != null) {
        inputRef.focus()
      }
    }, [inputRef])

    useEffect(() => {
      setValue(meta.row.values.assigned_number)
    }, [meta])

    const updateAssignedNumber = (participantId, assignedNumber) => {
      setValue(assignedNumber)
      setDirty((oldValue) => {
        return ++oldValue
      })
    }

    const showInputAndFocus = () => {
      setParticipantSelected(true)
    }

    if (participantSelected) {
      return (
        <div>
          <input
            ref={createInputRef}
            className='anInput'
            name='idInput'
            type='number'
            onBlur={() => {
              setParticipantSelected(false)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && e.target.value > 0) {
                assignParticipantNumber(meta.row.values.id, e.target.value)
                  .then((res) => {
                    if (res.status === 'error') {
                      messaging.errorMessage(res.error.message)
                    } else {
                      messaging.successMessage('Number successfully assigned')
                      updateAssignedNumber(meta.row.values.id, e.target.value)
                    }
                  })
                  .catch((err) => {
                    messaging.errorMessage(err)
                  })
                setParticipantSelected(false)
              }
            }}
          />
        </div>
      )
    }
    return (
      <div style={{ cursor: 'pointer' }} onClick={showInputAndFocus}>
        {value}
      </div>
    )
  }

  useEffect(() => {
    filterParticipants()
  }, [filterParticipants])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Participants',
        columns: [
          {
            Header: 'Id',
            accessor: 'id',
            Cell: ParticipantLink
          },
          {
            Header: 'Last Name',
            accessor: 'last_name'
          },
          {
            Header: 'First Name',
            accessor: 'first_name'
          },
          {
            Header: 'AN',
            accessor: 'assigned_number',
            Cell: AssignedNumber
          },
          {
            Header: 'Gender',
            accessor: 'gender'
          },
          {
            Header: 'Age',
            accessor: 'age'
          },
          {
            Header: 'Age Group',
            accessor: 'age_group'
          },
          {
            Header: showWithResults ? 'Points' : ' ',
            accessor: 'total_points'
          }
        ]
      }
    ],
    [showWithResults]
  )

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          style={{ width: '80px' }}
          placeholder='Assigned #'
          size='small'
          onChange={(e) => {
            const val = e.target.value
            if (val.length === 3) {
              setAssignedNumber(e.target.value)
              e.target.value = ''
            }
          }}
        />
        {assignedNumber !== '' && (
          <MdClear
            size='20px'
            color='red'
            onClick={() => {
              setAssignedNumber('')
            }}
          />
        )}
        {assignedNumber === '' && (
          <>
            <Dropdown
              size='10px'
              className='ml-2'
              onChange={handleGenderFilter}
              options={genderOptions}
              placeholder='Gender'
              selection
              clearable
              value={genderFilter}
            />
            <Dropdown
              size='10px'
              className='ml-2'
              onChange={handleAgeFilter}
              options={ageOptions}
              placeholder='Age Group'
              selection
              clearable
              value={ageFilter}
            />
          </>
        )}
      </div>
      <div
        style={{
          overflow: 'auto',
          height: 'calc(100vh - 125px)'
        }}
      >
        <button
          style={{
            margin: '.25rem',
            display: showWithResults ? 'inline-block' : 'none'
          }}
          className='ui blue button'
          size='small'
          onClick={() => {
            setShowDivisionLeaders(!showDivisionLeaders)
          }}
        >
          Show/Hide Division Leaders
        </button>
        <button
          style={{
            margin: '.25rem',
            display: showWithResults ? 'none' : 'inline-block'
          }}
          className='ui blue button'
          size='small'
          onClick={() => {
            setShowWithResults(true)
          }}
        >
          With Results
        </button>
        <button
          style={{
            margin: '.25rem',
            display: showWithResults ? 'inline-block' : 'none'
          }}
          className='ui blue button'
          size='small'
          onClick={() => {
            setShowWithResults(false)
          }}
        >
          Show All
        </button>
        <Styles>
          <div
            className='division-leaders'
            style={{
              display: showDivisionLeaders && showWithResults ? 'block' : 'none'
            }}
          >
            <h4
              style={{
                textAlign: 'center',
                textDecoration: 'underline',
                paddingBottom: '.5rem'
              }}
            >
              Division Leaders
            </h4>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div>
                <h5>Female</h5>
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'5-6'}
                  gender='Female'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'7-8'}
                  gender='Female'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'9-10'}
                  gender='Female'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'11-12'}
                  gender='Female'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'13-14'}
                  gender='Female'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'15+'}
                  gender='Female'
                />
              </div>
              <div>
                <h5>Male</h5>
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'5-6'}
                  gender='Male'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'7-8'}
                  gender='Male'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'9-10'}
                  gender='Male'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'11-12'}
                  gender='Male'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'13-14'}
                  gender='Male'
                />
                <ParticipantDivisionLeaders
                  participantsWithSummary={participants}
                  ageGroup={'15+'}
                  gender='Male'
                />
              </div>
            </div>
          </div>
          {filteredParticipants.length > 0 && (
            <Table columns={columns} data={filteredParticipants} />
          )}
          {filteredParticipants.length < 1 && (
            <h5 className='alert-success p-1 m-2'>
              No Participant Results Entered
            </h5>
          )}
        </Styles>
      </div>
    </div>
  )
}

export default ParticipantsPage

const Styles = styled.div`
  .division-leaders {
    border: 1px solid #ccc;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
  }
  table {
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      padding-right: 1rem;
      text-align: left;
    }
    td {
      :nth-child(4) {
        border: 1px solid #ccc;
        width: 80px;
        div {
          input.anInput {
            width: 50px;
            &:focus {
              background-color: #fff;
              outline: none !important;
              border: 1px solid #fff;
            }
          }
        }
      }
    }
    tr:first-child th:first-child {
      display: none;
    }
  }
`
