import React from 'react'
import {
  MdDone,
  MdInfoOutline,
  MdErrorOutline,
  MdWarning
} from 'react-icons/md'
import { toast } from 'react-toastify'

const successMessage = (msg) => {
  toast.success(
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <MdDone fontSize='24px' />
      {msg}
    </div>
  )
}
const errorMessage = (msg) => {
  toast.error(
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <MdErrorOutline fontSize='24px' />
      {msg}
    </div>
  )
}
const warningMessage = (msg) => {
  toast.warn(
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <MdWarning fontSize='24px' />
      {msg}
    </div>
  )
}
const infoMessage = (msg) => {
  toast.info(
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <MdInfoOutline fontSize='24px' />
      {msg}
    </div>
  )
}

const message = (msg) => {
  toast(
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <MdInfoOutline fontSize='24px' />
      {msg}
    </div>
  )
}

export const messaging = {
  errorMessage,
  successMessage,
  warningMessage,
  infoMessage,
  message
}
