import React from 'react'
import { Formik, Form } from 'formik'
import ModalWrapper from '../components/modals/ModalWrapper'
import * as Yup from 'yup'
import MyTextInput from '../components/form/MyTextInput'
import { Button, Label } from 'semantic-ui-react'
import { login } from '../api/userApi'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser } from '../store/userSlice'
import { closeModal } from '../store/modalSlice'

export default function LoginForm() {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  return (
    <ModalWrapper
      size='mini'
      header='Sign in'
      cancelCallback={() => {
        navigate('/')
      }}
    >
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={Yup.object({
          username: Yup.string().required(),
          password: Yup.string().required()
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const userData = await login(values)
            dispatch(setUser({ ...userData.userInfo, loggedIn: true }))
            setSubmitting(false)
            dispatch(closeModal())
            navigate('/meets/dashboard')
          } catch (error) {
            setErrors({ auth: 'Invalid username or password' })
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='username' placeholder='Username' />
            <MyTextInput
              name='password'
              placeholder='Password'
              type='password'
            />
            {errors.auth && (
              <Label
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!dirty || !isValid || isSubmitting}
              type='submit'
              fluid
              size='large'
              color='teal'
              content='Login'
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}
