import React from 'react'
import styled from 'styled-components'
import Spinner from 'react-spinkit'

const Loading = () => {
  return (
    <AppLoading>
      <AppLoadingContents>
        <Spinner name='ball-grid-pulse' color='purple' fadeIn='none' />
      </AppLoadingContents>
    </AppLoading>
  )
}

export default Loading

const AppLoading = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`

const AppLoadingContents = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  padding-bottom: 100px;
  flex-direction: column;
  justify-content: center;

  > img {
    object-fit: contain;
    height: 100px;
    margin-bottom: 40px;
  }
`
