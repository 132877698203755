import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getScheduledEvent,
  getScheduledEventResults,
  setEventPrintedStatus
} from '../api/meetApi'
import { messaging } from '../utils/toast'
import Ably from '../api/ablyApi'

const channel = Ably.channels.get('update-events')

const EventPrintResultsPage = () => {
  const [event, setEvent] = useState(null)
  const [eventResults, setEventResults] = useState([])

  const params = useParams()

  useEffect(() => {
    const eventId = params.scheduledEventId
    const body = document.getElementsByTagName('body')
    channel.publish('update-events', { eventId: eventId })

    if (body && body.length > 0) {
      body[0].classList.add('printing')
    }

    let isMounted = true
    if (eventId && isMounted) {
      getScheduledEvent(eventId).then((data) => {
        if (data.status === 'error') {
          messaging.errorMessage(data.message)
          return
        } else {
          setEventPrintedStatus(eventId, '1')
          setEvent(data)
        }
      })

      getScheduledEventResults(eventId).then((data) => {
        const resultsData = data.data
        resultsData.sort((a, b) => {
          let useA = '5'
          let useB = '5'
          if (
            a.overallPosition !== '0' &&
            a.overallPosition !== '' &&
            a.overallPosition != null
          )
            useA = a.overallPosition
          if (
            b.overallPosition !== '0' &&
            b.overallPosition !== '' &&
            b.overallPosition != null
          )
            useB = b.overallPosition
          return useA - useB
        })
        setEventResults(resultsData)
      })
    }

    return () => {
      isMounted = false
      if (body && body.length > 0) {
        body[0].classList.remove('printing')
      }
    }
  }, [params.scheduledEventId])

  useLayoutEffect(() => {
    setTimeout(() => {
      window.print()
    }, 1000)
  }, [])

  return (
    <div>
      {event && (
        <h4 className='my-1'>{`
        ${event.id} - ${event.name} - ages ${event.minimumAge} - ${event.maximumAge} for ${event.gender}
      `}</h4>
      )}
      <div className='results-data'>
        {event && eventResults && eventResults.length > 0 && (
          <div className='event-results my-1'>
            <table>
              <thead>
                <tr>
                  <th>AN</th>
                  <th>Name</th>
                  <th>{event.eventType === 'Track' ? '' : 'Atmpt'}</th>
                  <th>{event.eventType === 'Track' ? 'Time' : ''}</th>
                  <th>Overall Pos.</th>
                </tr>
              </thead>
              <tbody>
                {eventResults.map((row) => {
                  const useValue =
                    event.eventType === 'Track'
                      ? row.timeDisplay
                      : row.distanceDisplay
                  let useClass = row.ageMismatchBool
                    ? 'pr-1 age-mismatch'
                    : 'pr-1'
                  if (row.scratchBool) {
                    useClass += ' scratch'
                  }
                  let useTitle = row.ageMismatchBool ? 'Age mismatch' : ''
                  if (row.scratchBool) {
                    useTitle = 'Scratch'
                  }
                  return (
                    <tr key={row.id}>
                      <td className='pr-1'>{row.assignedNumber}</td>
                      <td title={useTitle} className={useClass}>
                        {row.name}
                      </td>
                      <td>{event.eventType === 'Track' ? '' : row.raceId}</td>
                      <td className='pr-3'>{useValue}</td>
                      <td>
                        {row.overallPosition === '0' ? '' : row.overallPosition}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default EventPrintResultsPage
